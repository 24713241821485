import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ScrollRestoration } from 'react-router-dom';
import {
	Box,
	Container,
	Heading,
	Text,
	VStack,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Skeleton,
	Button,
	Textarea,
	HStack,
} from '@chakra-ui/react';

import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import { AppContext } from '../../data/globalstate';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';

const ContactUsValidationSchema = yup.object({
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	email: yup.string().email('Must be a valid email').required('Email is required'),
	comments: yup.string().required('Comments are required'),
});

interface IContactForm {
	firstName?: string;
	lastName?: string;
	email?: string;
	comments?: string;
}

const ContactUsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user } = useContext(AppContext);
	const [showThanks, setShowThanks] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const formik = useFormik<IContactForm>({
		initialValues: {
			firstName: user?.burstProfile.firstName ?? '',
			lastName: user?.burstProfile.lastName ?? '',
			email: user?.burstProfile.email ?? '',
			comments: '',
		},
		validationSchema: ContactUsValidationSchema,
		// eslint-disable-next-line @typescript-eslint/require-await
		onSubmit: (values) => {
			console.log('Formik Submit::', values);
			if (values.firstName && values.lastName && values.email && values.comments) {
				setIsLoading(true);
				setTimeout(() => {
					setShowThanks(true);
					setIsLoading(false);
				}, 2500);
				// TODO
				// toastIdRef.current = toast({
				// 	title: 'Registering...',
				// 	status: 'loading',
				// 	id: 'register',
				// 	duration: null,
				// });
				// setLoading(true);
			}
		},
	});

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Contact Us" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Container maxWidth={'965px'} py={'4rem'}>
				<Heading as="h1" textTransform={'uppercase'}>
					CONTACT US
				</Heading>
				<Skeleton isLoaded={!isLoading}>
					{!showThanks && (
						<VStack marginTop={'40px'} p={'20px'}>
							<Text>
								Use the form below to contact our 24/7 Customer Service team. We're typically able
								to respond within 24 hours! For immediate assistance, please call our toll-free
								number or see Frequently Asked Questions.
							</Text>
							<Text>
								<strong>Phone Number:</strong> 877-527-0000
							</Text>
							<HStack gap={5} w={'100%'} alignItems={'flex-start'}>
								<VStack w={'50%'} gap={5}>
									<FormControl isRequired isInvalid={Boolean(formik.errors.firstName)}>
										<FormLabel htmlFor="firstName">First Name</FormLabel>
										<Input
											id="firstName"
											type="text"
											name="firstName"
											required
											value={formik.values.firstName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.errors.firstName && (
											<FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isRequired isInvalid={Boolean(formik.errors.lastName)}>
										<FormLabel htmlFor="lastName">Last Name</FormLabel>
										<Input
											id="lastName"
											type="text"
											name="lastName"
											required
											value={formik.values.lastName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.errors.lastName && (
											<FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
										<FormLabel htmlFor="email">Email</FormLabel>
										<Input
											id="email"
											type="email"
											name="email"
											required
											value={formik.values.email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
										{formik.errors.email && (
											<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
										)}
									</FormControl>
								</VStack>
								<VStack w={'50%'} gap={5}>
									<FormControl isRequired={true} isInvalid={Boolean(formik.errors.comments)}>
										<FormLabel htmlFor="comments" id="commentsLabel">
											Questions/Comments:
										</FormLabel>
										<Textarea
											id="comments"
											name={'comments'}
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											noOfLines={8}
											rows={8}
											value={formik.values.comments}
										/>
										{formik.errors.comments && (
											<FormErrorMessage>{formik.errors.comments}</FormErrorMessage>
										)}
									</FormControl>
								</VStack>
							</HStack>
							<Button
								textTransform="uppercase"
								variant="solid"
								colorScheme="secondary"
								color="white"
								size="lg"
								onClick={() => {
									formik.handleSubmit();
								}}
								isLoading={isLoading}
								mt={4}
								px={10}
							>
								Submit
							</Button>
						</VStack>
					)}
					{showThanks && (
						<VStack
							alignItems={'center'}
							justifyContent={'center'}
							marginTop={'40px'}
							paddingY={'50px'}
						>
							<Heading as="h2">Thank you for contacting us!</Heading>
						</VStack>
					)}
				</Skeleton>
				{/* TODO Make Address block dyanmic! */}
				<Text fontSize={'16px'} textAlign="left" marginTop={'30px'} paddingLeft={'40px'}>
					<strong>Mailing Address</strong>
					<br />
					Pinnacle Outdoor
					<br />
					P.O. Box 123456
					<br />
					Wethersfield, CT 06129-0728
				</Text>
			</Container>
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedContactUsPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <ContactUsPage ref={ref} />),
);

export default ContactUsPage;
