import { useContext, useEffect } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	VStack,
	Spinner,
	Heading,
	FormControl,
	FormLabel,
	Button,
	Text,
	FormErrorMessage,
	Textarea,
	HStack,
} from '@chakra-ui/react';
import React from 'react';
import { EPromotionType, IClaimPromotionRequest } from '../../../domain/models';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';

// TODO Refactor
interface IEarningAction {
	id: string;
	type: EPromotionType;
	action?: string;
	points: number;
	title: string;
	description: string;
	actionText?: string;
}

interface IProps {
	showModal: boolean;
	setShowModal: React.Dispatch<boolean>;
	earningAction?: IEarningAction;
	setSelectedAction: React.Dispatch<IEarningAction | undefined>;
}

// TODO Form Validation.
const ValidationScheme = yup.object({
	review: yup.string().required('You must enter a review.').trim('Review cannot be empty.'),
});

interface testQs {
	review?: string;
}

export const ReviewModal: React.FC<IProps> = ({
	showModal,
	setShowModal,
	earningAction,
	setSelectedAction,
}) => {
	const { user } = useContext(AppContext);
	const { useClaimEarningAction } = useApiManager();
	const { mutateAsync, error, isSuccess, isPending, reset } = useClaimEarningAction();
	const qc = useQueryClient();
	// TODO Get Profile Question Details
	// const [value, setValue] = React.useState('1');
	const formik = useFormik<testQs>({
		initialValues: {
			review: '',
		},
		validationSchema: ValidationScheme,
		onSubmit: () => {
			// console.log(values);
			const ea: IClaimPromotionRequest = {
				transactionDescription: earningAction?.title ?? 'Unknown Earning Action',
				membershipCode: user?.burstId ?? null,
				activityType: EPromotionType.ProductReview,
				activityKey: earningAction?.id ?? '',
				partnerCode: 'PG',
				transactionProperties: null,
				itemProperties: null,
			};
			// console.log('Claim Action', ea);
			mutateAsync(ea)
				.then(() => {
					// console.log('New Transaction Success :: ', v);
					// setShowModal(false);
					void qc.invalidateQueries({ queryKey: ['GetAction', earningAction?.id] });
					void qc.invalidateQueries({ queryKey: ['memberBalance'] });
					// reset();
				})
				.catch((e: unknown) => {
					console.error(e);
					// TODO
				});
		},
	});

	useEffect(() => {
		if (!showModal) {
			reset();
			setSelectedAction(undefined);
		}
		// eslint-disable-next-line
	}, [showModal]);

	return (
		<Modal
			size={'xl'}
			isOpen={showModal}
			onClose={() => {
				// KEEP OPEN
				// setShowModal(false);
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'} pos={'relative'}>
					<HStack
						backgroundColor={'secondary.500'}
						pos={'absolute'}
						top={0}
						left={'50%'}
						transform={'translateX(-50%)'}
						p={1}
						px={2}
						color={'white'}
					>
						<Text fontSize={{ base: 'md' }}>Earn {earningAction?.points ?? 0} points</Text>
					</HStack>
				</ModalHeader>
				<ModalCloseButton
					onClick={() => {
						setShowModal(false);
						formik.resetForm();
					}}
				/>
				<ModalBody>
					{error && (
						<Alert
							status="error"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							textAlign="center"
						>
							<AlertIcon />
							<AlertTitle>We encountered an error awarding your points.</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Alert>
					)}
					{isSuccess && (
						<VStack>
							<Alert
								status="success"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
							>
								<AlertIcon />
								<AlertTitle>You've earned {earningAction?.points} points!</AlertTitle>
								<AlertDescription>You may now close this window.</AlertDescription>
							</Alert>
							<Text>Completed on {new Date().toLocaleDateString()}</Text>
						</VStack>
					)}
					{isPending && (
						<VStack>
							<Spinner size="xl" color={'primary.500'} />
							<Heading>Claiming Points...</Heading>
						</VStack>
					)}
					{!isPending && !isSuccess && (
						<VStack>
							<FormControl isInvalid={Boolean(formik.errors.review)}>
								<FormLabel>Review</FormLabel>
								<Textarea
									id="review"
									name="review"
									placeholder="Type your review here."
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.review}
								/>
								{formik.errors.review && (
									<FormErrorMessage>{formik.errors.review}</FormErrorMessage>
								)}
							</FormControl>
							<Button
								colorScheme="quaternary"
								variant={'solid'}
								onClick={() => {
									formik.handleSubmit();
								}}
							>
								Submit
							</Button>
						</VStack>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
