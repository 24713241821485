import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ProductQuickShop } from './ProductQuickShop';
import { IRewardTypeDto } from '../../../domain/models';

export interface IProductQuickViewModalProps {
	isShown: boolean;
	product?: IRewardTypeDto;
}

export interface IProductQuickViewProps {
	quickViewProps: IProductQuickViewModalProps;
	setQuickViewProps: React.Dispatch<IProductQuickViewModalProps>;
}

const ProductQuickViewModal: React.FC<IProductQuickViewProps> = ({
	quickViewProps,
	setQuickViewProps,
}) => {
	const { isShown, product } = quickViewProps;
	// REVIEW Need to do something better with an empty product
	if (!product) {
		return null;
	}
	return (
		<Modal
			isOpen={isShown}
			onClose={() => {
				setQuickViewProps({ isShown: false, product: undefined });
			}}
			size="6xl"
		>
			<ModalOverlay />
			<ModalContent
				borderRadius="none"
				mx={{ base: '2.5', lg: '16' }}
				my={{ base: '2.5', md: '16' }}
			>
				<ModalCloseButton top="3" right="5" size="lg" />
				<ModalBody px={{ base: '5', lg: '16' }} pt="16" pb={{ base: '10', lg: '16' }}>
					<ProductQuickShop product={product} />
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default ProductQuickViewModal;
