import React from 'react';
import { VStack } from '@chakra-ui/react';

import WelcomeSidebar from './WelcomeSidebar';
import EpicRewards from './EpicRewards';
import SidebarPerks from './Perks';

const SidebarWhyJoin: React.FC = () => {
	return (
		<VStack gap={0} maxWidth={{ base: '100%', xl: '420px' }}>
			<WelcomeSidebar />
			<EpicRewards />
			<SidebarPerks showHeader={false} />
		</VStack>
	);
};

export default SidebarWhyJoin;
