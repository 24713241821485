import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import { ScrollRestoration } from 'react-router-dom';
import OrderSummary from '../components/orders/OrderSummary';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';

const OrderSummaryPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
	<Box textAlign="center" fontSize="xl" ref={ref}>
		{/* TODO Prefix Support? */}
		<PageTitle title="Order Summary" />
		<ScrollRestoration />
		<CartBar />
		<NavigationBar />
		<OrderSummary />
		<FooterThreeColumn />
	</Box>
));

export const AnimatedOrderSummaryPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <OrderSummaryPage ref={ref} />),
);

export default OrderSummaryPage;
