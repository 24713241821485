import { Box, BoxProps } from '@chakra-ui/react';

export const CarouselSlide: React.FC<BoxProps> = (props) => (
	<Box
		className="chakra-carousel__slide"
		position="relative"
		overflow="hidden"
		width="100%"
		minH="100%"
		{...props}
	/>
);
