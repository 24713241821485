import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import {
	ILoginRequest,
	ILoginResponse,
	IApiManager,
	IGetMemberBalanceRequestParameters,
	IGetMemberBalanceResponse,
	IGetRewardsRequestParameters,
	IGetRewardsResponse,
	IGetUserHistoryRequestParameters,
	IGetUserHistoryResponse,
	IMemberDto,
	INewTransactionResponse,
	IRedeemRewardRequest,
	IRedeemRewardResponse,
	IRegisterRequest,
	IUpdateMemberRequest,
	IUpdateMemberResponse,
	IClaimPromotionRequest,
	IGetTiersResponse,
	IGetUserEarningActionRequest,
	IGetVoucherTagsResponse,
} from '../models';
import { getCookie } from '../utils/CookieUtils';
import { objectToUrlParams } from '../utils/GeneralUtils';

let source = '';
if (process.env.REACT_APP_API_URL) {
	source = process.env.REACT_APP_API_URL;
}

export const apiManager = {};

export const useApiManager = (): IApiManager => {
	return {
		getApiUrl: () => {
			return source;
		},
		useLogin: () =>
			useMutation({
				mutationFn: async (data: ILoginRequest) => {
					const token = getCookie('accessToken');
					const url = source + '/login';
					const options: RequestInit = {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
						body: JSON.stringify({
							AccountNumber: data.accountNumber,
							PromotionName: data.promotionName,
						}),
					};

					return new Promise((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as ILoginResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data.member);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
				scope: {
					id: 'api:login',
				},
			}),
		useRegister: () =>
			useMutation({
				mutationFn: async (data: IRegisterRequest) => {
					// console.log(data.userName + data.password + data.firstName + data.lastName);
					// console.log(data.address.city);
					const url = source + '/register';
					const token = getCookie('accessToken');
					const options: RequestInit = {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
						body: JSON.stringify(data),
					};
					const response = await fetch(url, options);
					// console.log(response);
					const bu = (await response.json()) as { member: IMemberDto };
					return bu.member;
				},
				scope: {
					id: 'api:register',
				},
			}),
		useGetRewards: (params: IGetRewardsRequestParameters) =>
			useQuery({
				queryKey: ['rewards', 'get', params],
				queryFn: () => {
					// console.log('ProductBlock::getRewards');
					// console.log(getRewardsParams);

					const token = getCookie('accessToken');
					let url = source + '/Reward';

					url = url + '?' + objectToUrlParams(params);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetRewardsResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetRewardsResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
				placeholderData: keepPreviousData,
			}),
		useGetSpotlightRewards: (params: IGetRewardsRequestParameters) =>
			useQuery({
				queryKey: ['spotlightrewards', 'get', params],
				queryFn: () => {
					// console.log('ProductBlock::getRewards');
					// console.log(getRewardsParams);

					const token = getCookie('accessToken');
					let url = source + '/Reward';

					url = url + '?' + objectToUrlParams(params) + '&spotlight=true';
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetRewardsResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetRewardsResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
				placeholderData: keepPreviousData,
			}),
		useGetRewardDetails: (params: IGetRewardsRequestParameters) =>
			useQuery<IGetRewardsResponse>({
				queryKey: ['reward', 'get', params],
				queryFn: () => {
					// console.log('ProductBlock::getRewards');
					// console.log(getRewardsParams);

					const token = getCookie('accessToken');
					let url = source + '/Reward';

					url = url + '?' + objectToUrlParams(params);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetRewardsResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetRewardsResponse;
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			}),
		useGetMemberBalance: (data: IGetMemberBalanceRequestParameters) =>
			useQuery({
				queryKey: ['memberBalance'],
				queryFn: () => {
					// console.log('useApiManager::getMemberBalance');
					const token = getCookie('accessToken');
					let url = source + '/Membership/Balance';

					if (!data.membershipCode) {
						return Promise.reject('No Member Code!');
					}

					url = url + '?' + objectToUrlParams(data);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetMemberBalanceResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			}),
		useRedeemReward: () =>
			useMutation({
				mutationFn: async (data: IRedeemRewardRequest) => {
					// console.log(data.userName + data.password + data.firstName + data.lastName);
					// console.log(data.address.city);
					const url = source + '/Reward';
					const token = getCookie('accessToken');
					const options: RequestInit = {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
						body: JSON.stringify(data),
					};
					const response = await fetch(url, options);
					// console.log(response);
					const bu = (await response.json()) as IRedeemRewardResponse;
					return bu;
				},
				scope: {
					id: 'api:redeemReward',
				},
			}),
		useGetEarningActions: (data: IGetMemberBalanceRequestParameters) => {
			// TODO
			return useQuery({
				queryKey: ['GetEarningActions'],
				queryFn: () => {
					console.log('useApiManager::useGetEarningActions');
					const token = getCookie('accessToken');
					let url = source + '/Membership';

					url = url + '?' + objectToUrlParams(data);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetMemberBalanceResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useGetEarningsHistory: (data: IGetUserHistoryRequestParameters) => {
			return useQuery({
				queryKey: ['GetEarningsHistory', data],
				queryFn: () => {
					// console.log('useApiManager::useGetEarningsHistory');

					if (data.UserId.length <= 0) {
						return Promise.reject(new Error('Invalid user id!'));
					}

					const token = getCookie('accessToken');
					let url = source + '/Transaction';

					url = url + '?' + objectToUrlParams(data) + '&ActivityType=REWARD&PointType=BASE';
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetUserHistoryResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetUserHistoryResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useGetRedemptionHistory: (data: IGetUserHistoryRequestParameters) => {
			return useQuery({
				queryKey: ['GetRedemptionHistory', data],
				queryFn: () => {
					// console.log('useApiManager::useGetEarningsHistory');

					if (data.UserId.length <= 0) {
						return Promise.reject(new Error('Invalid user id!'));
					}

					const token = getCookie('accessToken');
					let url = source + '/Transaction';

					url = url + '?' + objectToUrlParams(data) + '&ActivityType=REDEEM&PointType=BASE';
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetUserHistoryResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetUserHistoryResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useClaimEarningAction: () =>
			useMutation({
				mutationFn: async (data: IClaimPromotionRequest) => {
					// console.log('useApiManager::useClaimEarningAction',data);
					const url = source + '/Transaction/claim';
					const token = getCookie('accessToken');
					const options: RequestInit = {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
						body: JSON.stringify(data),
					};
					return new Promise((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								if (response.ok) {
									const data = (await response.json()) as INewTransactionResponse;
									// console.log(response.status);
									// console.log(data);
									resolve(data);
								} else {
									reject(response);
								}
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
				scope: {
					id: 'api:useClaimEarningAction',
				},
			}),
		useGetMembership: (data) => {
			return useQuery({
				queryKey: ['GetMembership', data.membershipCode],
				queryFn: () => {
					// console.log('useApiManager::useGetMembership');

					if (data.membershipCode.length <= 0) {
						return Promise.reject(new Error('Invalid user id!'));
					}

					const token = getCookie('accessToken');
					let url = source + '/Membership';

					url = url + '?' + objectToUrlParams(data);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<ILoginResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as ILoginResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useUpdateMembership: () =>
			useMutation({
				mutationFn: async (data: IUpdateMemberRequest) => {
					console.log('useApiManager::useUpdateMembership', data);
					const url = source + '/Membership';
					const token = getCookie('accessToken');
					const options: RequestInit = {
						method: 'PUT',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
						body: JSON.stringify(data),
					};
					const response = await fetch(url, options);
					// console.log(response);
					const bu = (await response.json()) as IUpdateMemberResponse;
					return bu;
				},
				scope: {
					id: 'api:useUpdateMembership',
				},
			}),
		useGetTiers: () => {
			return useQuery({
				queryKey: ['GetTiers'],
				queryFn: () => {
					// console.log('useApiManager::useGetMembership');

					// if (data.membershipCode.length <= 0) {
					// 	return Promise.reject(new Error('Invalid user id!'));
					// }
					// TODO - Make PartnerCode & TierSystem configuration variables.

					const token = getCookie('accessToken');
					const url = source + '/promo/tiers?PartnerCode=PG&TierSystem=PGTIE000367';

					// url = url + '?' + objectToUrlParams(data);
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetTiersResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetTiersResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useGetAction: (data: IGetUserEarningActionRequest) => {
			return useQuery({
				queryKey: ['GetAction', data.promo],
				queryFn: () => {
					// console.log('useApiManager::useGetAction');

					if (data.promo.length <= 0) {
						return Promise.reject(new Error('Invalid action id!'));
					}

					if (!data.UserId) {
						//NOTE - No User, just return without error!
						return Promise.resolve({});
					}

					const token = getCookie('accessToken');
					const url = `${source}/Transaction/promo?${objectToUrlParams(data)}&PointType=BASE`;
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							// 'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetUserHistoryResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetUserHistoryResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
		useGetTags: () => {
			return useQuery({
				queryKey: ['GetTags'],
				queryFn: () => {
					// console.log('useApiManager::useGetAction');

					const token = getCookie('accessToken');
					const url = `${source}/Reward/tags?PartnerCode=PG`; //TODO: Make variables dynamic
					// console.log(url);

					const options: RequestInit = {
						method: 'GET',
						headers: {
							// 'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + (token ? token : ''),
							'Access-Control-Allow-Origin': '*',
						},
					};

					return new Promise<IGetVoucherTagsResponse>((resolve, reject) => {
						fetch(url, options)
							.then(async (response) => {
								const data = (await response.json()) as IGetVoucherTagsResponse;
								// console.log(response.status);
								// console.log(data);
								resolve(data);
							})
							.catch((e: unknown) => {
								reject(e);
							});
					});
				},
			});
		},
	};
};
