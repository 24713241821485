import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Card,
	CardBody,
	Heading,
	HStack,
	Skeleton,
	useBreakpointValue,
	VStack,
	Link,
	Image,
	Text,
	AspectRatio,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../internal/carousel';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useApiManager } from '../../../domain/hooks';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import { AppContext } from '../../../data/globalstate';

const PointsCircle: React.FC<{ points: number }> = ({ points }) => {
	return (
		<VStack
			w={'64px'}
			h={'64px'}
			// py={4}
			gap={0}
			borderRadius={'50%'}
			border={'2px solid #fff'}
			backgroundColor={'primary.500'}
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			color={'white'}
			position={'absolute'}
			top={'5%'}
			right={'5%'}
			zIndex={10}
		>
			<Heading as={'span'} size={'md'} fontWeight={'bold'} lineHeight={'1em'}>
				{points.toLocaleString('en-US')}
			</Heading>
			<Text fontSize={{ base: 'sm' }} textTransform={'uppercase'} lineHeight={'0.8em'}>
				Points
			</Text>
		</VStack>
	);
};

const RandomProductSlider: React.FC = () => {
	const { appConfiguration } = useContext(AppContext);
	const [getRewardsParams] = useState<IGetRewardsRequestParameters>({
		PartnerCode: 'PG',
		IsActive: true,
		PageSize: 9,
		PageIndex: 1,
		SortBy: 'CreatedOn',
		SortDirection: 'desc',
	});
	const { useGetRewards } = useApiManager();
	const { data, isLoading, error } = useGetRewards(getRewardsParams);
	const slidesPerView = useBreakpointValue({ base: 3, md: 5 });
	const [ref, slider] = useCarousel({
		loop: true,
		mode: 'free-snap',
		slides: {
			perView: slidesPerView,
			spacing: useBreakpointValue({ base: 16, md: 24 }),
		},
		// slideChanged: (s) => {
		// 	console.log('carousel changed', s.track.details.rel);
		// },
	});

	return (
		<Box as="section" color={'secondary.500'}>
			<VStack py="10" gap={10}>
				<Heading textTransform="uppercase" as="h2" size="md">
					redeem points for great rewards
				</Heading>
				<Skeleton isLoaded={!isLoading} w={'100%'}>
					<HStack spacing="4" maxW={useBreakpointValue({ base: '100%', md: '80%' })} mx={'auto'}>
						{error && (
							<Alert status="error">
								<AlertIcon />
								<Box>
									<AlertTitle>{error.name}</AlertTitle>
									<AlertDescription>{error.message}</AlertDescription>
								</Box>
							</Alert>
						)}
						{!error && (
							<>
								<CarouselIconButton
									onClick={() => slider.current?.prev()}
									icon={<IoChevronBackOutline />}
									aria-label="Previous slide"
									ml={2}
								/>
								<Carousel ref={ref} direction="row" width="full">
									{data?.rewards?.map((product, i) => {
										let primaryImageUrl = product.imageURL;
										let primaryAltText = product.voucherName;

										if (!primaryImageUrl) {
											const match = product.imageAlternatives?.filter((v) => {
												return v.isPrimary;
											});
											if (match) {
												primaryImageUrl = match[0]?.url;
												primaryAltText = match[0]?.altText;
											}
										}
										return (
											<CarouselSlide key={i} cursor="pointer">
												<Card
													borderRadius={'none'}
													border={'4px solid'}
													borderColor={'secondary.500'}
													height={'100%'}
													p={0}
												>
													<CardBody
														p={1}
														minH={'100px'}
														display={'flex'}
														flexDir={'column'}
														// justifyContent={'space-evenly'}
													>
														<PointsCircle points={product.points ?? 0} />
														{/* <VStack
															w={'64px'}
															h={'64px'}
															borderRadius={'32px'}
															backgroundColor={'primary.500'}
															color={'white'}
															gap={0}
															justifyContent={'center'}
															position={'absolute'}
															top={2}
															right={2}
															border={'2px solid white'}
															zIndex={100}
														>
															<Text fontSize={'medium'} fontWeight={'bold'}>
																{product.points?.toLocaleString('en-US')}
															</Text>
															<Text fontSize={'small'}>Points</Text>
														</VStack> */}
														<Link
															as={NavLink}
															to={product.rewardId ? `/product/${product.rewardId}` : '#'}
														>
															<AspectRatio ratio={4 / 3}>
																<Image
																	src={
																		primaryImageUrl ??
																		appConfiguration?.placeHolderImage ??
																		undefined
																	}
																	alt={primaryAltText ?? 'Unknown'}
																	draggable="false"
																	fallback={<Skeleton />}
																	borderRadius="none"
																	// @ts-expect-error Need to add CSS !important
																	objectFit={'contain !important'}
																/>
															</AspectRatio>
														</Link>
														<VStack flex={1} justifyContent={'center'}>
															<Link
																as={NavLink}
																to={product.rewardId ? `/product/${product.rewardId}` : '#'}
															>
																<Text
																	fontWeight={'bold'}
																	textAlign={'center'}
																	fontSize={{ base: 'md' }}
																	textTransform={'uppercase'}
																>
																	{product.voucherName}
																</Text>
															</Link>
														</VStack>
													</CardBody>
												</Card>
											</CarouselSlide>
										);
									})}
								</Carousel>
								<CarouselIconButton
									onClick={() => slider.current?.next()}
									icon={<IoChevronForwardOutline />}
									aria-label="Next slide"
								/>
							</>
						)}
					</HStack>
				</Skeleton>
				<Button
					as={NavLink}
					to={'/rewards'}
					textTransform="uppercase"
					variant="solid"
					bg="secondary.500"
					color="white"
					_hover={{ opacity: '0.8' }}
					size="md"
				>
					view rewards catalog
				</Button>
			</VStack>
		</Box>
	);
};
export default RandomProductSlider;
