import { Box, Heading, Img, Text, VStack } from '@chakra-ui/react';

interface IPageHeaderCenteredOnAccentProps {
	logo: string;
	heading: string;
	text: string;
}

const PageHeaderCenteredOnAccent: React.FC<IPageHeaderCenteredOnAccentProps> = ({
	logo,
	heading,
	text,
}) => {
	return (
		<Box as="section" bg="#e7d5d1" color="secondary.500" py={'10'}>
			<VStack gap={2}>
				<Img src={logo} alt="logo" />
				<Heading size={{ base: 'md' }} fontWeight="bold" textTransform="uppercase">
					{heading}
				</Heading>
				<Text fontSize={{ base: 'md' }} maxW="3xl">
					{text}
				</Text>
			</VStack>
		</Box>
	);
};
export default PageHeaderCenteredOnAccent;
