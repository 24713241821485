import {
	AspectRatio,
	Button,
	Container,
	Heading,
	Image,
	Img,
	SimpleGrid,
	Stack,
	Text,
	useColorMode,
	VStack,
} from '@chakra-ui/react';
import SpecialBadge from '../SpecialBadge';
import { NavLink } from 'react-router-dom';

interface IHeroImageLeft {
	marginTop?: string;
	heroImageUrl: string;
	heroImageAlt: string;
	contentBgColor?: string;
	showSpecialBadge?: boolean;
}

const HeroImageLeft: React.FC<IHeroImageLeft> = ({
	marginTop,
	heroImageUrl,
	heroImageAlt,
	contentBgColor,
	showSpecialBadge,
}) => {
	const { colorMode } = useColorMode();
	return (
		<Container maxW={'7xl'} mt={marginTop} position={'relative'} p={0}>
			<SimpleGrid columns={{ base: 1, md: 2 }} gap={0}>
				<AspectRatio ratio={4 / 3}>
					<Image objectFit="fill" src={heroImageUrl} alt={heroImageAlt} objectPosition={'left'} />
				</AspectRatio>
				<VStack justifyContent="center" bg={contentBgColor} px={'40px'}>
					<Stack
						spacing={{ base: '4', md: '6' }}
						px={showSpecialBadge ? 10 : undefined}
						alignItems={'center'}
					>
						<Img
							src="https://burst-staging.ebbo.com/media/wysiwyg/home/stars.png"
							alt="Stars"
							w={100}
							mt={5}
						/>
						<Heading
							size={{ base: 'md', md: 'xl' }}
							textTransform={'uppercase'}
							color={colorMode === 'light' ? 'white' : undefined}
						>
							you could win an all-inclusive vacation package
						</Heading>
						<Text
							fontSize={{ base: 'lg', md: 'xl' }}
							color={colorMode === 'light' ? 'white' : undefined}
						>
							In Arizona, see the legendary Grand Canyon, where you explore the park&rsquo;s trails
							by day and spend your nights on the edge of the canyon. Prize includes air
							transportation, hotel and guided tour.
						</Text>
						<Text fontSize={'small'} color={colorMode === 'light' ? 'white' : undefined}>
							*To enter without redeeming points, see Official Rules.
						</Text>
					</Stack>
					<Stack direction={{ base: 'column', md: 'row' }} spacing="3">
						<Button
							as={NavLink}
							to={'/rewards'}
							textTransform="uppercase"
							variant="solid"
							color="white"
							bg="secondary.500"
							_hover={{ opacity: '0.8' }}
							size="md"
							mt={'30px'}
							mb={'20px'}
						>
							REDEEM NOW
						</Button>
					</Stack>
				</VStack>
			</SimpleGrid>
			{showSpecialBadge && (
				<SpecialBadge title={'SWEEPSTAKES ENTRY'} keyText="50" subText="POINTS" />
			)}
		</Container>
	);
};
export default HeroImageLeft;
