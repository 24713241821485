import { Pagination as ArkPagination } from '@ark-ui/react';
import { Button, Center, IconButton, List, ListItem, Skeleton, Text } from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

export type PaginationProps = Omit<ArkPagination.RootProps, 'children'>;
interface IPagination {
	isLoading: boolean;
	props: PaginationProps;
	textColor?: string;
}

export const Pagination: React.FC<IPagination> = ({ props, isLoading, textColor }) => {
	return (
		<ArkPagination.Root {...props}>
			<ArkPagination.Context>
				{({ pages, page }) => (
					<Skeleton isLoaded={!isLoading}>
						<List display="flex" justifyContent="space-between">
							<ListItem>
								<ArkPagination.PrevTrigger asChild>
									<IconButton
										variant="secondary"
										isRound
										icon={<FiArrowLeft />}
										aria-label="Previous Page"
										// isLoading={isLoading}
										color={textColor}
									/>
								</ArkPagination.PrevTrigger>
							</ListItem>

							<List display={{ base: 'none', md: 'flex' }} gap="1">
								{pages.map((p, index) =>
									p.type === 'page' ? (
										<ListItem key={index}>
											<ArkPagination.Item asChild {...p}>
												<Button
													variant="secondary"
													borderRadius="full"
													_selected={{
														bg: 'gray.50',
														color: 'black',
														_dark: { bg: 'gray.800', color: 'white' },
													}}
													color={textColor}
													isLoading={isLoading}
												>
													{p.value}
												</Button>
											</ArkPagination.Item>
										</ListItem>
									) : (
										<ListItem key={index} alignItems="center" display="flex">
											<ArkPagination.Ellipsis index={index}>
												<Button
													variant="secondary"
													borderRadius="full"
													pointerEvents="none"
													width="10"
													color={textColor}
												>
													&#8230;
												</Button>
											</ArkPagination.Ellipsis>
										</ListItem>
									),
								)}
							</List>
							<ListItem as={Center} display={{ md: 'none' }}>
								<Text fontWeight="medium" color="fg.emphasized">
									Page {page} of {pages.length + 1}
								</Text>
							</ListItem>

							<ListItem>
								<ArkPagination.NextTrigger asChild>
									<IconButton
										variant="secondary"
										isRound
										icon={<FiArrowRight />}
										aria-label="Next Page"
										// isLoading={isLoading}
										color={textColor}
									/>
								</ArkPagination.NextTrigger>
							</ListItem>
						</List>
					</Skeleton>
				)}
			</ArkPagination.Context>
		</ArkPagination.Root>
	);
};
