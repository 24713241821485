import { useEffect, useState } from 'react';
import { useApiManager } from '../../../domain/hooks';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import {
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Popover,
	Radio,
	Skeleton,
	Stack,
	useColorModeValue,
} from '@chakra-ui/react';
import { ProductFilterPopoverButton, ProductFilterPopoverContent } from './ProductFilterPopover';
import { FiSearch } from 'react-icons/fi';
import { isEqual } from 'lodash';

interface Option {
	label: string;
	value: string;
}
interface IFilterProps {
	getRewardsParams: IGetRewardsRequestParameters;
	setGetRewardsParams: React.Dispatch<React.SetStateAction<IGetRewardsRequestParameters>>;
	multiSelect?: boolean;
	label?: string;
	hideLabel?: boolean;
	showSearch?: boolean;
	setSelectedFilters: React.Dispatch<React.SetStateAction<Option[] | undefined>>;
	selectedFilters?: Option[];
}

// TODO - Implement Multi when available in BURST.
const ProductCategoryFilter: React.FC<IFilterProps> = ({
	hideLabel,
	label,
	showSearch,
	getRewardsParams,
	setGetRewardsParams,
	setSelectedFilters,
	selectedFilters,
}) => {
	const { useGetTags } = useApiManager();
	const { data, isLoading } = useGetTags();
	const [options, setOptions] = useState<Option[]>([]);
	const colorValue = useColorModeValue('secondary.500', 'secondary.200');
	const [selectedValue, setSelectedValue] = useState<Option[] | undefined>();

	useEffect(() => {
		// console.log(
		// 	'selectedFilters::changed::',
		// 	selectedFilters,
		// 	selectedValue,
		// 	isEqual(selectedFilters, selectedValue),
		// );
		if (!isEqual(selectedFilters, selectedValue)) {
			setSelectedValue(selectedFilters);
		}
		setGetRewardsParams({
			...getRewardsParams,
			Tag: selectedFilters?.[0].value,
		});
		// eslint-disable-next-line
	}, [selectedFilters]);

	// useEffect(() => {
	// 	console.log('selectedValue::changed::', selectedValue);
	// }, [selectedValue]);

	useEffect(() => {
		const newOpts: Option[] = [];
		data?.tags?.forEach((v) => {
			newOpts.push({ label: v.voucherTagName ?? 'UNKNOWN', value: v.voucherTagCode ?? 'NONE' });
		});
		setOptions(newOpts);
		// eslint-disable-next-line
	}, [data]);

	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Category" />
			<ProductFilterPopoverContent
				onClickApply={() => {
					// setGetRewardsParams({
					// 	...getRewardsParams,
					// 	Tag: selectedValue?.[0].value,
					// });
					setSelectedFilters(selectedValue);
				}}
				onClickCancel={() => {
					// setGetRewardsParams({
					// 	...getRewardsParams,
					// 	Tag: undefined,
					// });
					if (!isEqual(selectedFilters, selectedValue)) {
						setSelectedValue(undefined);
					}
				}}
				showReset
				onClickReset={() => {
					// console.log('Reset Clicked');
					setSelectedFilters(undefined);
					// setGetRewardsParams({
					// 	...getRewardsParams,
					// 	Tag: undefined,
					// });
					// setSelectedValue(undefined);
				}}
			>
				<Skeleton isLoaded={!isLoading}>
					<Stack as="fieldset" spacing={'2'}>
						{!hideLabel && (
							<FormLabel fontWeight="semibold" as="legend" mb="0">
								{label}
							</FormLabel>
						)}
						{showSearch && (
							<InputGroup size="md" pb="1">
								<Input placeholder="Search..." rounded="md" focusBorderColor={colorValue} />
								<InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
									<FiSearch />
								</InputRightElement>
							</InputGroup>
						)}
						<Stack>
							{options.map((option) => (
								<Radio
									key={option.value}
									value={option.value}
									colorScheme="secondary"
									isChecked={selectedValue ? selectedValue.includes(option) : false}
									onChange={(e) => {
										// console.log(e.target.value);
										const opt = options.find((v) => v.value === e.target.value);
										// console.log(opt);
										if (opt) {
											setSelectedValue([opt]);
										} else {
											setSelectedValue(undefined);
										}
									}}
								>
									{option.label}
								</Radio>
							))}
						</Stack>
					</Stack>
				</Skeleton>
			</ProductFilterPopoverContent>
		</Popover>
	);
};
export default ProductCategoryFilter;
