import {
	Link,
	VStack,
	Heading,
	Text,
	Button,
	Skeleton,
	HStack,
	Icon,
	Progress,
} from '@chakra-ui/react';
import {
	EPromotionType,
	IClaimPromotionRequest,
	IEarningAction,
	ITransactionDto,
} from '../../../domain/models';
import { useCopyToClipboard } from 'usehooks-ts';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../data/globalstate';
import { NavLink, useNavigate } from 'react-router-dom';
import { useApiManager } from '../../../domain/hooks';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { useQueryClient } from '@tanstack/react-query';
// import { v7 as uuidv7 } from 'uuid';

interface IEarningActionCardProps {
	item: IEarningAction;
	setSelectedAction: React.Dispatch<IEarningAction>;
	setShowVideoPlayer: React.Dispatch<boolean>;
	setShowPoll: React.Dispatch<boolean>;
	setShowProfileForm: React.Dispatch<boolean>;
	setShowReviewForm: React.Dispatch<boolean>;
}

const completedHelper = (
	item: IEarningAction,
	transactions: ITransactionDto[] | null | undefined,
) => {
	let complete = false;

	switch (item.type) {
		case EPromotionType.Anniversary:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.Purchase:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.AcceptedReferral:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.Poll:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.Profile:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.Video:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.OptIn:
			if ((transactions?.length ?? 0) > 0) {
				complete = true;
			}
			break;
		case EPromotionType.ProductReview:
			if ((transactions?.length ?? 0) > 3) {
				complete = true;
			}
			break;
		default:
			complete = false;
			break;
	}
	return complete;
};

const PointsCircle: React.FC<{ points: number }> = ({ points }) => {
	return (
		<VStack
			w={'72px'}
			h={'72px'}
			gap={0}
			borderRadius={'50%'}
			border={'2px solid #fff'}
			backgroundColor={'primary.500'}
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			color={'white'}
		>
			<Heading as={'span'} size={'md'} fontWeight={'bold'} lineHeight={'1em'}>
				{points}
			</Heading>
			<Text fontSize={'sm'} textTransform={'uppercase'} lineHeight={'0.8em'}>
				Points
			</Text>
		</VStack>
	);
};

export const EarningActionCard: React.FC<IEarningActionCardProps> = ({
	item,
	setSelectedAction,
	setShowVideoPlayer,
	setShowPoll,
	setShowReviewForm,
}) => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const [copiedUrl, copyUrl] = useCopyToClipboard();
	const [copiedCode, copyCode] = useCopyToClipboard();
	const { useGetAction, useClaimEarningAction } = useApiManager();
	const { mutateAsync, isPending, reset } = useClaimEarningAction();
	const { data, isLoading } = useGetAction({ UserId: user?.burstId, promo: item.id });
	const [loading, setLoading] = useState<boolean>(false);
	const qc = useQueryClient();
	const isComplete = completedHelper(item, data?.transactions);

	useEffect(() => {
		if (isLoading || isPending) {
			setLoading(true);
		}
		if (!isLoading && !isPending) {
			setLoading(false);
		}
		// es-lint-disable-next-line
	}, [isLoading, isPending]);

	const claimAction = () => {
		// console.log('Claim Action', item);
		const ea: IClaimPromotionRequest = {
			transactionDescription: item.title,
			membershipCode: user?.burstId ?? null,
			activityType: item.type,
			activityKey: item.id,
			partnerCode: 'PG',
			transactionProperties: null,
			itemProperties: null,
		};
		// console.log(ea);
		mutateAsync(ea)
			.then(() => {
				// console.log('New Transaction Success :: ', v);
				void qc.invalidateQueries({ queryKey: ['GetAction', item.id] });
				void qc.invalidateQueries({ queryKey: ['memberBalance'] });
				reset();
			})
			.catch((e: unknown) => {
				console.error(e);
				// TODO
			});
	};

	return (
		<Skeleton isLoaded={!loading} display={'flex'}>
			<VStack background={'url("/media/images/chart-bg.webp")'} flex={1}>
				<VStack
					m="25px"
					p={'20px'}
					height={'calc(100% - 40px)'}
					// justifyContent={'space-between'}
					alignItems={'center'}
					// TODO Need to figure out how to calculate rgb from color token and apply alpha - This should be secondary.500 with alpha
					backgroundColor={isComplete ? 'rgba(126,152,167,.89)' : 'whiteAlpha.900'}
					// backgroundColor={'blackAlpha.800'} rgba(126,152,167,.89)
					textAlign={'center'}
					color={'secondary.500'}
					// _after={{
					// 	backgroundColor: isComplete ? 'rgba(1, 44, 66, .5)' : 'none',
					// #0381c1
					//rgba(3, 129, 193, .5) //100
					//rgba(2, 96, 142, .5) //300
					// }}
					alignSelf={'stretch'}
				>
					{item.type === EPromotionType.Anniversary && (
						<>
							{/* Completed */}
							{isComplete && (
								<VStack minH={'72px'} alignSelf={'stretch'}>
									<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
										<Text textTransform={'uppercase'}>completed</Text>
										<Icon as={FaCheckCircle} color={'tertiary.500'} />
									</HStack>
								</VStack>
							)}
							{!isComplete && <PointsCircle points={item.points} />}
							<Heading as="h1" size="lg" fontWeight="extrabold" textTransform="uppercase" maxW="md">
								{item.title}
							</Heading>
							<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
								{item.description}
							</Text>
							<Link textDecor={'underline'} textTransform={'uppercase'} fontSize={{ base: 'md' }}>
								Automatic
							</Link>
						</>
					)}
					{item.type === EPromotionType.Purchase && (
						<>
							{/* Completed */}
							{isComplete && (
								<VStack minH={'72px'} alignSelf={'stretch'}>
									<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
										<Text textTransform={'uppercase'}>completed</Text>
										<Icon as={FaCheckCircle} color={'tertiary.500'} />
									</HStack>
								</VStack>
							)}
							{!isComplete && <PointsCircle points={item.points} />}
							<Heading as="h1" size="lg" fontWeight="extrabold" textTransform="uppercase" maxW="md">
								{item.title}
							</Heading>
							<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
								{item.description}
							</Text>
							<Link
								textDecor={'underline'}
								textTransform={'uppercase'}
								target="_blank"
								href={item.action}
								fontSize={{ base: 'md' }}
							>
								{item.actionText}
							</Link>
						</>
					)}
					{item.type === EPromotionType.AcceptedReferral && (
						<>
							{/* Completed */}
							{isComplete && (
								<VStack minH={'72px'} alignSelf={'stretch'}>
									<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
										<Text textTransform={'uppercase'}>completed</Text>
										<Icon as={FaCheckCircle} color={'tertiary.500'} />
									</HStack>
								</VStack>
							)}
							{!isComplete && <PointsCircle points={item.points} />}
							<Heading as="h1" size="lg" fontWeight="extrabold" textTransform="uppercase" maxW="md">
								{item.title}
							</Heading>
							<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
								{item.description}
							</Text>
							{!isComplete && (
								<VStack>
									<Button
										colorScheme="white"
										variant={'link'}
										textDecor={copiedUrl ? 'none' : 'underline'}
										textTransform={'uppercase'}
										size={'md'}
										onClick={() => {
											if (!user) {
												navigate('/login');
												return;
											}
											copyUrl(
												`https://www.ebbo.com/?utm_source=google&utm_medium=cpc&utm_campaign=&gad_source=1&gclid=EAIaIQobChMI65jjqv6xiQMVWmBHAR1U8B0hEAAYASAAEgL6LfD_BwE`,
											).catch((error: unknown) => {
												console.error('Failed to copy!', error);
											});
										}}
										isDisabled={copiedUrl ? true : false}
										disabled={copiedUrl ? true : false}
									>
										{copiedUrl ? 'Copied' : 'copy my unique link'}
									</Button>
									<Text fontSize={{ base: 'sm' }}>— or —</Text>
									<Button
										colorScheme="white"
										variant={'link'}
										textDecor={copiedCode ? 'none' : 'underline'}
										textTransform={'uppercase'}
										size={copiedCode ? 'sm' : 'md'}
										isDisabled={copiedCode ? true : false}
										disabled={copiedCode ? true : false}
										onClick={() => {
											if (!user) {
												navigate('/login');
												return;
											}
											copyCode('PeakReferral_2579').catch((error: unknown) => {
												console.error('Failed to copy!', error);
											});
										}}
										userSelect={'all'}
									>
										{copiedCode ? copiedCode : 'Get my unique code'}
									</Button>
								</VStack>
							)}
						</>
					)}
					{item.type === EPromotionType.Poll && (
						<>
							{/* Completed */}
							{isComplete && (
								<>
									<VStack minH={'72px'} alignSelf={'stretch'}>
										<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
											<Text textTransform={'uppercase'}>completed</Text>
											<Icon as={FaCheckCircle} color={'tertiary.500'} />
										</HStack>
										<Text>Take a new poll on 05/01/25</Text>
									</VStack>
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<VStack
										alignSelf={'stretch'}
										backgroundColor={'rgba(255,255,255,0.4)'}
										borderRadius={10}
										p={2}
									>
										<HStack alignSelf={'stretch'}>
											<Progress
												value={50}
												flex={1}
												colorScheme="primary"
												height={'24px'}
												backgroundColor={'secondary.500'}
												borderRadius={4}
											>
												<Text
													position={'absolute'}
													top={1}
													left={2}
													color={'primaryText.500'}
													fontSize={{ base: 'sm' }}
												>
													Mountains
												</Text>
											</Progress>
											<Text fontSize={{ base: 'sm' }}>50%</Text>
										</HStack>
										<HStack alignSelf={'stretch'}>
											<Progress
												value={30}
												flex={1}
												colorScheme="primary"
												height={'24px'}
												backgroundColor={'secondary.500'}
												borderRadius={4}
											>
												<Text
													position={'absolute'}
													top={1}
													left={2}
													color={'primaryText.500'}
													fontSize={{ base: 'sm' }}
												>
													Beaches
												</Text>
											</Progress>
											<Text fontSize={{ base: 'sm' }}>30%</Text>
										</HStack>
										<HStack alignSelf={'stretch'}>
											<Progress
												value={10}
												flex={1}
												colorScheme="primary"
												height={'24px'}
												backgroundColor={'secondary.500'}
												borderRadius={4}
											>
												<Text
													position={'absolute'}
													top={1}
													left={2}
													color={'primaryText.500'}
													fontSize={{ base: 'sm' }}
												>
													Forests
												</Text>
											</Progress>
											<Text fontSize={{ base: 'sm' }}>10%</Text>
										</HStack>
										<HStack alignSelf={'stretch'}>
											<Progress
												value={0}
												flex={1}
												colorScheme="primary"
												height={'24px'}
												backgroundColor={'secondary.500'}
												borderRadius={4}
											>
												<Text
													position={'absolute'}
													top={1}
													left={2}
													color={'primaryText.500'}
													fontSize={{ base: 'sm' }}
												>
													Deserts
												</Text>
											</Progress>
											<Text fontSize={{ base: 'sm' }}>0%</Text>
										</HStack>
									</VStack>
									<Text fontSize={{ base: 'md' }} mt="2" maxW="sm">
										{item.points} points earned
									</Text>
								</>
							)}
							{/* Not Completed */}
							{!isComplete && (
								<>
									<PointsCircle points={item.points} />
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Link
										textDecor={'underline'}
										textTransform={'uppercase'}
										target="_blank"
										fontSize={{ base: 'md' }}
										onClick={() => {
											if (!user) {
												navigate('/login');
												return;
											}
											// console.log('Do Poll!');
											setSelectedAction(item);
											setShowPoll(true);
										}}
									>
										{item.actionText}
									</Link>
								</>
							)}
						</>
					)}
					{item.type === EPromotionType.Profile && (
						<>
							{/* Completed */}
							{isComplete && (
								<>
									<VStack minH={'72px'} alignSelf={'stretch'}>
										<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
											<Text textTransform={'uppercase'}>completed</Text>
											<Icon as={FaCheckCircle} color={'tertiary.500'} />
										</HStack>
									</VStack>
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Text fontSize={{ base: 'lg' }} mt="2" maxW="sm">
										{item.points} points earned
									</Text>
								</>
							)}
							{/* Not Completed */}
							{!isComplete && (
								<>
									<PointsCircle points={item.points} />
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Link
										as={NavLink}
										to={'/profile'}
										textDecor={'underline'}
										textTransform={'uppercase'}
										fontSize={{ base: 'md' }}
										// target="_blank"
										// onClick={() => {
										// 	if (!user) {
										// 		navigate('/login');
										// 		return;
										// 	}
										// 	setSelectedAction(item);
										// 	setShowProfileForm(true);
										// }}
									>
										{item.actionText}
									</Link>
								</>
							)}
						</>
					)}
					{item.type === EPromotionType.Video && (
						<>
							{/* Completed */}
							{isComplete && (
								<>
									<VStack minH={'72px'} alignSelf={'stretch'}>
										<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
											<Text textTransform={'uppercase'}>completed</Text>
											<Icon as={FaCheckCircle} color={'tertiary.500'} />
										</HStack>
									</VStack>
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Text fontSize={{ base: 'md' }} mt="2" maxW="sm">
										{item.points} points earned
									</Text>
								</>
							)}
							{/* Not Completed */}
							{!isComplete && (
								<>
									<PointsCircle points={item.points} />
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Link
										textDecor={'underline'}
										textTransform={'uppercase'}
										target="_blank"
										fontSize={{ base: 'md' }}
										onClick={() => {
											if (!user) {
												navigate('/login');
												return;
											}
											if (item.action) {
												setSelectedAction(item);
												setShowVideoPlayer(true);
											}
										}}
									>
										{item.actionText}
									</Link>
								</>
							)}
						</>
					)}
					{item.type === EPromotionType.OptIn && (
						<>
							{/* Completed */}
							{isComplete && (
								<>
									<VStack minH={'72px'} alignSelf={'stretch'}>
										<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
											<Text textTransform={'uppercase'}>completed</Text>
											<Icon as={FaCheckCircle} color={'tertiary.500'} />
										</HStack>
									</VStack>
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="4" maxW="sm">
										{item.description}
									</Text>
									<Text fontSize={{ base: 'md' }} mt="4" maxW="sm">
										{item.points} points earned
									</Text>
								</>
							)}
							{/* Not Completed */}
							{!isComplete && (
								<>
									<PointsCircle points={item.points} />
									<Heading
										as="h1"
										size="lg"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.title}
									</Heading>
									<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
										{item.description}
									</Text>
									<Link
										textDecor={'underline'}
										textTransform={'uppercase'}
										target="_blank"
										fontSize={{ base: 'md' }}
										onClick={() => {
											if (!user) {
												navigate('/login');
												return;
											}
											claimAction();
										}}
									>
										{item.actionText}
									</Link>
								</>
							)}
						</>
					)}
					{item.type === EPromotionType.ProductReview && (
						<>
							{/* Completed */}
							{isComplete && (
								<VStack minH={'72px'} alignSelf={'stretch'}>
									<HStack alignSelf={'stretch'} justifyContent={'space-between'}>
										<Text textTransform={'uppercase'}>completed</Text>
										<Icon as={FaCheckCircle} color={'tertiary.500'} />
									</HStack>
								</VStack>
							)}
							{/* Not Completed */}
							{/* {(data?.transactions?.length ?? 0) <= 3 && (
								<> */}
							{!isComplete && <PointsCircle points={item.points} />}
							<Heading as="h1" size="lg" fontWeight="extrabold" textTransform="uppercase" maxW="md">
								{item.title}
							</Heading>
							<Text fontSize={{ base: 'sm' }} mt="2" maxW="sm">
								{item.description}
							</Text>
							<HStack mt={4}>
								<Icon
									as={(data?.transactions?.length ?? 0) > 0 ? FaCheckCircle : FaRegCircle}
									color={(data?.transactions?.length ?? 0) > 0 ? 'tertiary.500' : 'secondary.500'}
								/>
								<Icon
									as={(data?.transactions?.length ?? 0) > 1 ? FaCheckCircle : FaRegCircle}
									color={(data?.transactions?.length ?? 0) > 1 ? 'tertiary.500' : 'secondary.500'}
								/>
								<Icon
									as={(data?.transactions?.length ?? 0) > 2 ? FaCheckCircle : FaRegCircle}
									color={(data?.transactions?.length ?? 0) > 2 ? 'tertiary.500' : 'secondary.500'}
								/>
								<Icon
									as={(data?.transactions?.length ?? 0) > 3 ? FaCheckCircle : FaRegCircle}
									color={(data?.transactions?.length ?? 0) > 3 ? 'tertiary.500' : 'secondary.500'}
								/>
							</HStack>
							<Text fontSize={{ base: 'sm' }} maxW="sm" textTransform={'uppercase'}>
								You have made {data?.transactions?.length ?? 0}/4 reviews this year
							</Text>
							{!isComplete && (
								<Link
									textDecor={'underline'}
									textTransform={'uppercase'}
									target="_blank"
									fontSize={{ base: 'md' }}
									onClick={() => {
										if (!user) {
											navigate('/login');
											return;
										}
										// claimAction();
										setSelectedAction(item);
										setShowReviewForm(true);
									}}
								>
									{item.actionText}
								</Link>
							)}
							{/* </>
							)} */}
						</>
					)}
				</VStack>
			</VStack>
		</Skeleton>
	);
};
