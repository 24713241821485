import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

// TODO Convert to a reusable component.

const EpicRewards: React.FC = () => {
	return (
		<Box px={'70px'} py={'30px'} color={'#012d43'} backgroundColor={'rgba(212, 146, 131, 0.3)'}>
			<Heading as="h2" textTransform={'uppercase'} fontSize={'20px'}>
				EPIC&nbsp;ADVENTURES DESERVE EPIC&nbsp;REWARDS
			</Heading>
			<Text>Gear up for your next adventure with great rewards, starting now.</Text>
		</Box>
	);
};

export default EpicRewards;
