import { Flex, FlexProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
	return (
		<Flex
			ref={ref}
			className="chakra-carousel"
			overflow="hidden"
			position="relative"
			userSelect="none"
			w={'100%'}
			{...props}
		/>
	);
});
