import { IconButton, IconButtonProps } from '@chakra-ui/react';

export const CarouselIconButton: React.FC<IconButtonProps> = (props) => (
	<IconButton
		{...props}
		fontSize="2xl"
		isRound
		boxShadow="-4px 4px 6px rgba(24, 37, 170, .2)"
		bg={'white'}
		color={'secondary.500'}
		_hover={{
			bg: 'gray.200',
		}}
		_focus={{ boxShadow: 'inerhit' }}
		_focusVisible={{ boxShadow: 'outline' }}
		w={'40px'}
		h={'40px'}
	/>
);
