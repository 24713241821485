import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ICart, ICartItem, ICartManager } from '../models';
import { useContext } from 'react';
import { AppContext } from '../../data/globalstate';

export const useCartManager = (): ICartManager => {
	const queryClient = useQueryClient();
	const { appConfiguration, setShowCart, user } = useContext(AppContext);

	const getCartItems = useQuery({
		queryKey: ['cart', 'items'],
		queryFn: () => {
			// console.log('useCartManager::getCartItems');
			let lsData = localStorage.getItem('cart');
			if (user === undefined) {
				return Promise.reject(new Error('No User!'));
			}
			// const uid = user.burstId;
			if (lsData === null) {
				// Populate empty
				// const newD: ICartData = { items: [], subtotal: 0 };
				// const newD: ICart = { id: user.burstId, data: { items: [], subtotal: 0 } };
				const newD: ICart = { [user.burstId]: { items: [], subtotal: 0 } };
				localStorage.setItem('cart', JSON.stringify(newD));
				lsData = localStorage.getItem('cart');
				if (lsData) {
					const d = JSON.parse(lsData) as ICart;
					// const x = d[user.burstId]
					return Promise.resolve(d[user.burstId].items);
				}
				return Promise.reject(new Error('Invalid Cart Data'));
			} else {
				const d = JSON.parse(lsData) as ICart;
				// return Promise.resolve(d.items);
				return Promise.resolve(d[user.burstId].items);
			}
			// return Promise.reject(new Error('Invalid Cart Data'));
		},
	});

	const setCartItems = useMutation({
		mutationFn: (items: ICartItem[]) => {
			return new Promise<void>((resolve) => {
				if (user === undefined) {
					resolve();
					return;
				}

				const lsData = localStorage.getItem('cart');
				if (lsData === null) {
					let subtotal = 0;
					items.forEach((v) => {
						subtotal += v.quantity * (v.itemData.points ?? 0);
					});
					// const newD: ICartData = { items: items, subtotal: subtotal };
					const newD: ICart = { [user.burstId]: { items: items, subtotal: subtotal } };
					localStorage.setItem('cart', JSON.stringify(newD));
					resolve();
					return;
				}
				const d = JSON.parse(lsData) as ICart;
				d[user.burstId].items = items;
				let subT = 0;
				items.forEach((v) => {
					subT += v.quantity * (v.itemData.points ?? 0);
				});
				d[user.burstId].subtotal = subT;
				localStorage.setItem('cart', JSON.stringify(d));
				resolve();
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['cart'] });
			await queryClient.fetchQuery({ queryKey: ['cart'] });
		},
		scope: {
			id: 'cart',
		},
	});

	const changeQuantity = useMutation({
		mutationFn: (data: { item: ICartItem; quantity: number }) => {
			return new Promise<void>((resolve, reject) => {
				if (user === undefined) {
					resolve();
					return;
				}
				//Do NOT allow less than 1
				if (data.quantity < 1) {
					resolve();
					return;
				}
				const items = getCartItems.data;
				// const items = undefined;
				if (!items) {
					resolve();
					return;
				}

				const match = items.find((v) => v.itemData.rewardId === data.item.itemData.rewardId);
				if (match) {
					match.quantity = data.quantity;
				}
				setCartItems.mutateAsync(items).catch((e: unknown) => {
					reject(e);
					return;
				});

				resolve();
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['cart'] });
			await queryClient.fetchQuery({ queryKey: ['cart'] });
		},
		scope: {
			id: 'cart',
		},
	});

	return {
		useGetCart: () =>
			useQuery({
				queryKey: ['cart'],
				queryFn: () => {
					// console.log('useCartManager::getCart');
					if (user === undefined) {
						// No Logged in user, just resolve with empty data.
						return Promise.resolve({});
					}
					let lsData = localStorage.getItem('cart');
					// console.log(lsData);
					if (lsData === null) {
						//Populate empty
						// const newD: ICartData = { user.burstId:{items: [], subtotal: 0 }};
						// const newD:ICartData = {user!.burstId:{}}
						const newD: ICart = { [user.burstId]: { items: [], subtotal: 0 } };
						localStorage.setItem('cart', JSON.stringify(newD));

						lsData = localStorage.getItem('cart');
						if (lsData) {
							const d = JSON.parse(lsData) as ICart;
							// cartManager.setCartData?.(d);
							return Promise.resolve(d);
						}
						return Promise.reject(new Error('Invalid Cart Data'));
					} else {
						const d = JSON.parse(lsData) as ICart;
						// cartManager.setCartData?.(d);
						// console.log(d);
						// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
						if (d[user.burstId] === undefined) {
							d[user.burstId] = { items: [], subtotal: 0 };
							localStorage.setItem('cart', JSON.stringify(d));
						}
						return Promise.resolve(d);
					}
					// return Promise.reject(new Error('Invalid Cart Data'));
				},
			}),
		useGetCartItems: () => getCartItems,
		useSetCartItems: () => setCartItems,
		useRemoveCartItem: () =>
			useMutation({
				mutationFn: (item: ICartItem) => {
					return new Promise<void>((resolve) => {
						// console.log(item);
						if (user === undefined) {
							resolve();
							return;
							// return Promise.reject(new Error('No User!'));
						}
						const lsData = localStorage.getItem('cart');
						if (lsData !== null) {
							const d = JSON.parse(lsData) as ICart;
							const newItems = d[user.burstId].items.filter(
								(v) => v.itemData.rewardId !== item.itemData.rewardId,
							);
							let subT = 0;
							newItems.forEach((v) => {
								subT += v.quantity * (v.itemData.points ?? 0);
							});
							d[user.burstId].items = newItems;
							d[user.burstId].subtotal = subT;
							localStorage.setItem('cart', JSON.stringify(d));
						}
						resolve();
					});
				},
				onSuccess: async () => {
					await queryClient.invalidateQueries({ queryKey: ['cart'] });
					await queryClient.fetchQuery({ queryKey: ['cart'] });
				},
				scope: {
					id: 'cart',
				},
			}),
		useAddCartItem: () =>
			useMutation({
				mutationFn: (item: ICartItem) => {
					return new Promise<void>((resolve) => {
						// console.log(item);
						if (user === undefined) {
							resolve();
							return;
							// return Promise.reject(new Error('No User!'));
						}
						const lsData = localStorage.getItem('cart');
						if (lsData !== null) {
							const d = JSON.parse(lsData) as ICart;
							const itmInCart = d[user.burstId].items.find(
								(v) => v.itemData.rewardId === item.itemData.rewardId,
							);
							if (itmInCart) {
								itmInCart.quantity += 1;
							} else {
								d[user.burstId].items.push(item);
							}
							let subT = 0;
							d[user.burstId].items.forEach((v) => {
								subT += v.quantity * (v.itemData.points ?? 0);
							});
							d[user.burstId].subtotal = subT;
							localStorage.setItem('cart', JSON.stringify(d));
						}

						if (!appConfiguration?.useCartPage) {
							setShowCart(true);
							setTimeout(() => {
								setShowCart(false);
							}, 3000);
						}

						resolve();
					});
				},
				onSuccess: async () => {
					await queryClient.invalidateQueries({ queryKey: ['cart'] });
					await queryClient.fetchQuery({ queryKey: ['cart'] });
				},
				scope: {
					id: 'cart',
				},
			}),
		useChangeQuantity: () => changeQuantity,
	};
};
