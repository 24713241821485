import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	AlertTitle,
	Alert,
	AlertDescription,
	AlertIcon,
	VStack,
	Spinner,
	Heading,
	Progress,
	HStack,
	Text,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useApiManager } from '../../../domain/hooks';
import { EPromotionType, IClaimPromotionRequest } from '../../../domain/models';
import { AppContext } from '../../../data/globalstate';
import { OnProgressProps } from 'react-player/base';
import { useQueryClient } from '@tanstack/react-query';

// TODO Refactor
interface IEarningAction {
	id: string;
	type: EPromotionType;
	action?: string;
	points: number;
	title: string;
	description: string;
	actionText?: string;
}

interface IVideoPlayerProps {
	showModal: boolean;
	setShowModal: React.Dispatch<boolean>;
	earningAction?: IEarningAction;
	setSelectedAction: React.Dispatch<IEarningAction | undefined>;
}

const pad = (string: number) => {
	return ('0' + string.toString()).slice(-2);
};

const formatTime = (seconds: number) => {
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = pad(date.getUTCSeconds());
	if (hh) {
		return `${hh.toString()}:${pad(mm)}:${ss}`;
	}
	return `${mm.toString()}:${ss}`;
};

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({
	showModal,
	setShowModal,
	earningAction,
	setSelectedAction,
}) => {
	const { user } = useContext(AppContext);
	const { useClaimEarningAction } = useApiManager();
	const { mutateAsync, error, isSuccess, isPending, reset } = useClaimEarningAction();
	const [videoWatched, setVideoWatched] = useState<boolean>(false);
	const [progress, setProgress] = useState<OnProgressProps>();
	const [duration, setDuration] = useState<number>(0);
	const qc = useQueryClient();
	const handleVideoEnded = () => {
		// console.log('Video Ended');
		setVideoWatched(true);
		// const ea: INewTransactionRequest = {
		// 	transactionCode: uuidv7(),
		// 	transactionDescription: earningAction?.title ?? 'Unknown Earning Action',
		// 	membershipCode: user?.burstId ?? null,
		// 	items: [
		// 		{
		// 			quantity: 1,
		// 			grossValue: earningAction?.points ?? 0,
		// 			productKey: '',
		// 			properties: {
		// 				// profile: '1',
		// 				PromotionCode: earningAction?.id ?? '',
		// 				Activity: 'true',
		// 			},
		// 		},
		// 	],
		// 	transactionTypeCode: 'REWARD',
		// 	currencyCode: 'USD',
		// 	partnerCode: 'PG',
		// 	properties: null,
		// 	alternateCustomerId: '',
		// };
		const ea: IClaimPromotionRequest = {
			transactionDescription: earningAction?.title ?? 'Unknown Earning Action',
			membershipCode: user?.burstId ?? null,
			activityType: EPromotionType.Video,
			activityKey: earningAction?.id ?? '',
			partnerCode: 'PG',
			transactionProperties: null,
			itemProperties: null,
		};
		// console.log('Claim Action', ea);
		mutateAsync(ea)
			.then((v) => {
				console.log('New Transaction Success :: ', v);
				void qc.invalidateQueries({ queryKey: ['GetAction', earningAction?.id] });
				void qc.invalidateQueries({ queryKey: ['memberBalance'] });
			})
			.catch((e: unknown) => {
				console.error(e);
				// TODO
			});
	};

	//Reset Video Watched tracking.
	useEffect(() => {
		if (!showModal) {
			setVideoWatched(false);
			reset();
			setSelectedAction(undefined);
		}
		// eslint-disable-next-line
	}, [showModal]);

	return (
		<Modal
			size={'xl'}
			isOpen={showModal}
			onClose={() => {
				// KEEP OPEN
				// setShowModal(false);
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'} pos={'relative'}>
					<HStack
						backgroundColor={'secondary.500'}
						pos={'absolute'}
						top={0}
						left={'50%'}
						transform={'translateX(-50%)'}
						p={1}
						px={2}
						color={'white'}
					>
						<Text fontSize={{ base: 'md' }}>Earn {earningAction?.points ?? 0} points</Text>
					</HStack>
				</ModalHeader>
				{/* {videoWatched && ( */}
				<ModalCloseButton
					onClick={() => {
						setShowModal(false);
					}}
				/>
				{/* )} */}
				<ModalBody>
					{error && (
						<Alert
							status="error"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							textAlign="center"
						>
							<AlertIcon />
							<AlertTitle>We encountered an error awarding your points.</AlertTitle>
							<AlertDescription>{error.message}</AlertDescription>
						</Alert>
					)}
					{isSuccess && (
						<Alert
							status="success"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							textAlign="center"
						>
							<AlertIcon />
							<AlertTitle>You've earned {earningAction?.points} points!</AlertTitle>
							<AlertDescription>You may now close this window.</AlertDescription>
						</Alert>
					)}
					{isPending && (
						<VStack>
							<Spinner size="xl" color={'primary.500'} />
							<Heading>Claiming Points...</Heading>
						</VStack>
					)}
					{!videoWatched && (
						<VStack>
							<ReactPlayer
								url={earningAction?.action}
								// url={'https://www.youtube.com/watch?v=oUFJJNQGwhk'} //Long Video
								// light
								width={'100%'}
								onEnded={handleVideoEnded}
								// onBuffer={() => {
								// 	console.log('onBuffer');
								// }}
								onProgress={(p) => {
									// console.log('onProgress', p);
									setProgress(p);
								}}
								onDuration={(d) => {
									// console.log('onDuration', d);
									setDuration(d);
								}}
								// onReady={(p) => {
								// 	console.log('onReady', p);
								// }}
							/>
							<HStack alignItems={'center'} alignSelf={'stretch'}>
								<Text fontSize={'small'}>{formatTime(progress?.playedSeconds ?? 0)}</Text>
								<VStack position={'relative'} flex={1} gap={0} height={'var(--chakra-sizes-2)'}>
									<Progress
										id="loadedProgress"
										size={'sm'}
										colorScheme="secondary"
										value={progress?.loaded ? progress.loaded * 100 : 0}
										position={'absolute'}
										w={'100%'}
										backgroundColor={'transparent'}
									/>
									<Progress
										id="playedProgress"
										size={'sm'}
										colorScheme="primary"
										value={progress?.played ? progress.played * 100 : 0}
										position={'absolute'}
										w={'100%'}
										backgroundColor={'transparent'}
									/>
								</VStack>
								<Text fontSize={'small'}>{formatTime(duration)}</Text>
							</HStack>
						</VStack>
					)}

					{/* REVIEW: Do we need/want fullscreen support? yarn add screenfull. */}
					{/* <Button
						onClick={() => {
							screenfull.request(document.querySelector('.react-player'));
						}}
					>
						Fullscreen
					</Button> */}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
