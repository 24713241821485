import { Box, SimpleGrid } from '@chakra-ui/react';
import ProductCard from './ProductCard';
import ProductQuickViewModal, { IProductQuickViewModalProps } from './ProductQuickViewModal';
import { useState } from 'react';
import { IGetRewardsResponse } from '../../../domain/models';

interface IProductGridProps {
	data?: IGetRewardsResponse;
}

const ProductSpotlightGrid: React.FC<IProductGridProps> = ({ data }) => {
	const [quickViewProps, setQuickViewProps] = useState<IProductQuickViewModalProps>({
		isShown: false,
	});
	return (
		<Box
			maxW="5xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
			mt={'calc((192px/4) + 10px)'}
		>
			<SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap={{ base: '6', lg: '4' }}>
				{data?.rewards?.map((item) => (
					<ProductCard
						key={item.rewardId}
						product={item}
						quickViewProps={quickViewProps}
						setQuickViewProps={setQuickViewProps}
					/>
				))}
				{/* {products.map((product) => (
					<ProductCard
						key={product.rewardId}
						product={product}
						quickViewProps={quickViewProps}
						setQuickViewProps={setQuickViewProps}
					/>
				))} */}
			</SimpleGrid>
			{/* Product QuickView */}
			<ProductQuickViewModal
				quickViewProps={quickViewProps}
				setQuickViewProps={setQuickViewProps}
			/>
		</Box>
	);
};
export default ProductSpotlightGrid;
