import { useContext, useEffect, useState } from 'react';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Card,
	CardBody,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { allCountries } from 'country-region-data';

const EditFormValidationSchema = yup.object({
	address1: yup.string().required('Address is Required'),
	country: yup.string().required('Country is Required'),
	state: yup.string().required('State/Province is Required'),
	city: yup.string().required('City is Required'),
	zip: yup
		.string()
		.required('Zip/Postal Code is Required')
		.matches(
			/(^[\d]{5}$)|(^[\d]{5}-[\d]{4}$)/,
			'Invalid Zip/Postal Code, please enter a valid Zip/Postal Code',
		),
});

interface IEditForm {
	address1?: string;
	address2?: string;
	country: string;
	state?: string;
	city?: string;
	zip?: string;
}
interface IStateData {
	name: string;
	code: string;
}

const AccountAddresses: React.FC = () => {
	// TODO - Need support for multiple addresses for this to work like the mockups.
	const { user } = useContext(AppContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [stateData, setStateData] = useState<IStateData[]>([]);
	const { useGetMembership, useUpdateMembership } = useApiManager();
	const {
		data,
		isLoading,
		error: getError,
		refetch: refreshGet,
	} = useGetMembership({ membershipCode: user?.burstId ?? '' });
	const { mutateAsync, isPending, error: updateError, reset: resetUpdate } = useUpdateMembership();

	const formik = useFormik<IEditForm>({
		initialValues: {
			address1: data?.member.address.address1 ?? '',
			address2: data?.member.address.address2 ?? '',
			country: data?.member.address.country ?? '',
			state: data?.member.address.state ?? '',
			city: data?.member.address.city ?? '',
			zip: data?.member.address.zipCode ?? '',
		},
		validationSchema: EditFormValidationSchema,
		onSubmit: (values) => {
			console.log('Formik Submit::', values);
			// FIXME - Address is immutable in burst.
			if (data) {
				setLoading(true);
				void mutateAsync({
					...data.member,
					id: data.member.id ?? null,
					promotionCode: null,
					birthdate: data.member.birthdate ?? new Date('1900-01-01T00:00:00'),
					address: {
						address1: values.address1,
						address2: values.address2,
						country: values.country,
						state: values.state,
						city: values.city,
						zipCode: values.zip,
					},
				})
					.then(() => {
						setShowEdit(false);
						formik.resetForm();
						resetUpdate();
						void refreshGet();
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
	});

	//Ensure Formik Initial Values
	useEffect(() => {
		// console.log('Data Changed::', data);
		if (data) {
			//Reset Initial Values
			formik.initialValues.address1 = data.member.address.address1 ?? '';
			formik.initialValues.address2 = data.member.address.address2 ?? '';
			formik.initialValues.country = data.member.address.country ?? 'US';
			formik.initialValues.state = data.member.address.state ?? '';
			formik.initialValues.city = data.member.address.city ?? '';
			formik.initialValues.zip = data.member.address.zipCode ?? '';
			//Set Current Values
			void formik.setValues(
				{
					address1: data.member.address.address1 ?? '',
					address2: data.member.address.address2 ?? '',
					country: data.member.address.country ?? 'US',
					state: data.member.address.state ?? '',
					city: data.member.address.city ?? '',
					zip: data.member.address.zipCode ?? '',
				},
				false,
			);
		}
		// eslint-disable-next-line
	}, [data]);

	// Merge multiple possible loading triggers into one.
	useEffect(() => {
		if (isLoading || isPending) {
			setLoading(true);
		}
		if (!isLoading && !isPending) {
			setLoading(false);
		}
	}, [isLoading, isPending]);
	// Load States on Country Change
	useEffect(() => {
		const countryData = allCountries.find((v) => v[1] === formik.values.country);
		if (countryData) {
			const states = countryData[2];
			const newStates: IStateData[] = [];
			states.forEach((v) => {
				newStates.push({ name: v[0], code: v[1] });
			});
			setStateData(newStates);
		}
	}, [formik.values.country]);

	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
			alignItems={'flex-start'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				ADDRESSES
			</Heading>
			{getError && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Error retrieving your addresses.</AlertTitle>
					<AlertDescription>{getError.message}</AlertDescription>
				</Alert>
			)}
			<VStack w={'80%'} alignSelf={'center'} alignItems={'flex-start'} my={6}>
				<HStack justifyContent={'flex-start'} alignSelf={'stretch'} flexWrap={'wrap'}>
					<Skeleton isLoaded={!loading}>
						<Card>
							<CardBody>
								<VStack alignItems={'flex-start'}>
									<Text fontWeight={'bold'}>Default Address</Text>
									<Text>
										{data?.member.firstName} {data?.member.lastName}
									</Text>
									<Text>{data?.member.address.address1}</Text>
									{data?.member.address.address2 && <Text>{data.member.address.address2}</Text>}
									{data?.member.address.address3 && <Text>{data.member.address.address3}</Text>}
									<Text>
										{`${data?.member.address.city ?? ''}, ${data?.member.address.state ?? ''} ${data?.member.address.zipCode ?? ''}`}
									</Text>
									<Text>{data?.member.address.country}</Text>
									<Text>{data?.member.phone}</Text>
									<Button
										variant={'link'}
										textDecoration={'underline'}
										textTransform={'uppercase'}
										fontSize={'large'}
										colorScheme="primary"
										onClick={() => {
											setShowEdit(true);
										}}
									>
										Edit
									</Button>
								</VStack>
							</CardBody>
						</Card>
					</Skeleton>
				</HStack>
			</VStack>
			<Modal
				isOpen={showEdit}
				onClose={() => {
					formik.resetForm();
					setShowEdit(false);
					resetUpdate();
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={'center'}>Edit Address</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<VStack gap={5}>
							{updateError && (
								<Alert status="error">
									<AlertIcon />
									<AlertTitle>Error updating your profile.</AlertTitle>
									<AlertDescription>{updateError.message}</AlertDescription>
								</Alert>
							)}
							<FormControl isRequired isInvalid={Boolean(formik.errors.address1)}>
								<FormLabel htmlFor="address1">Street Address</FormLabel>
								<Input
									id="address1"
									type="text"
									name="address1"
									required
									value={formik.values.address1}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.errors.address1 && (
									<FormErrorMessage>{formik.errors.address1}</FormErrorMessage>
								)}
							</FormControl>
							<FormControl>
								<Input
									id="address2"
									type="text"
									name="address2"
									required
									value={formik.values.address2}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									aria-label="Address 2"
								/>
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.country)}>
								<FormLabel htmlFor="country">Country</FormLabel>
								<Select
									id="country"
									name="country"
									placeholder="Select option"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.country}
								>
									{allCountries.map((v, i) => {
										return (
											<option key={`countryOption_${i.toString()}`} value={v[1]}>
												{v[0]}
											</option>
										);
									})}
								</Select>
								{formik.errors.country && (
									<FormErrorMessage>{formik.errors.country}</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.state)}>
								<FormLabel htmlFor="state">State/Province</FormLabel>
								<Select
									id="state"
									name="state"
									placeholder="Please select a region, state or province."
									value={formik.values.state}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								>
									{stateData.map((v, i) => {
										return (
											<option key={`stateOption_${i.toString()}`} value={v.code}>
												{v.name}
											</option>
										);
									})}
								</Select>
								{formik.errors.state && <FormErrorMessage>{formik.errors.state}</FormErrorMessage>}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.city)}>
								<FormLabel htmlFor="city">City</FormLabel>
								<Input
									id="city"
									type="text"
									name="city"
									required
									value={formik.values.city}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.errors.city && <FormErrorMessage>{formik.errors.city}</FormErrorMessage>}
							</FormControl>
							<FormControl isRequired isInvalid={Boolean(formik.errors.zip)}>
								<FormLabel htmlFor="zip">Zip/Postal Code</FormLabel>
								<Input
									id="zip"
									type="text"
									name="zip"
									required
									value={formik.values.zip}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								{formik.errors.zip && <FormErrorMessage>{formik.errors.zip}</FormErrorMessage>}
							</FormControl>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="primary"
							isLoading={loading}
							onClick={() => {
								void formik.submitForm();
							}}
						>
							Update
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default AccountAddresses;
