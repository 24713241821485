import {
	HStack,
	Icon,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	Text,
	useColorModeValue as mode,
	usePopoverContext,
} from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import {
	ProductFilterActionButtons,
	ProductFilterActionButtonsProps,
} from './ProductFilterActionButtons';

interface FilterPopoverButtonProps {
	label: string;
	icon?: ElementType;
	selected?: boolean;
}

export const ProductFilterPopoverButton: React.FC<FilterPopoverButtonProps> = (props) => {
	const { label, icon, selected } = props;

	return (
		<PopoverTrigger>
			<HStack
				justify="space-between"
				position="relative"
				as="button"
				fontSize="sm"
				borderWidth="1px"
				zIndex="11"
				rounded="lg"
				paddingStart="3"
				paddingEnd="2"
				paddingY="1.5"
				spacing="1"
				data-selected={selected}
				_expanded={{ bg: mode('gray.100', 'gray.700') }}
				_selected={{ bg: 'blue.50', borderColor: 'blue.500' }}
			>
				{icon && <Icon as={icon} boxSize="2" />}
				<Text fontWeight="medium">{label}</Text>
				<Icon as={HiChevronDown} fontSize="xl" color="gray.400" />
			</HStack>
		</PopoverTrigger>
	);
};

type FilterPopoverContentProps = ProductFilterActionButtonsProps & {
	header?: string;
	children?: ReactNode;
};

export const ProductFilterPopoverContent: React.FC<FilterPopoverContentProps> = (props) => {
	const {
		header,
		children,
		onClickCancel,
		onClickApply,
		isCancelDisabled,
		onClickReset,
		showReset,
	} = props;
	const { onClose } = usePopoverContext();
	return (
		<Portal>
			<PopoverContent _focus={{ shadow: 'none', outline: 0 }} _focusVisible={{ shadow: 'outline' }}>
				{header && <PopoverHeader srOnly>{header}</PopoverHeader>}
				<PopoverBody padding="6">{children}</PopoverBody>
				<PopoverFooter>
					<ProductFilterActionButtons
						onClickCancel={() => {
							onClickCancel?.();
							onClose();
						}}
						isCancelDisabled={isCancelDisabled}
						onClickApply={() => {
							onClickApply?.();
							onClose();
						}}
						showReset={showReset}
						onClickReset={() => {
							onClickReset?.();
							onClose();
						}}
					/>
				</PopoverFooter>
			</PopoverContent>
		</Portal>
	);
};
