import { useContext, useEffect } from 'react';
import { useAuthManager } from '../../../domain/hooks';
import { AppContext } from '../../../data/globalstate';
import { expireCookie } from '../../../domain/utils/CookieUtils';

const AuthHandler: React.FC = () => {
	const { user, appConfiguration, setUser } = useContext(AppContext);
	const { useValidate: authValidate } = useAuthManager();
	const { mutateAsync: validateAuthAsync } = authValidate();

	useEffect(() => {
		// console.log('AuthHandler::useEffect');
		// console.log('AuthHandler::userChanged::user', user);
		// console.log('AuthHandler::userChanged::appconfig', appConfiguration);
		// expireCookie('accessToken');
		if (!appConfiguration) {
			return;
		}
		if (user !== undefined) {
			return;
		}
		validateAuthAsync(undefined)
			// .then((v: boolean) => {
			// 	console.log(v);
			// })
			.catch((e: unknown) => {
				console.log(e);
				expireCookie('accessToken');
				setUser(undefined);
				window.location.assign('/');
			});
		// eslint-disable-next-line
	}, [user, appConfiguration]);

	return null;
};
export default AuthHandler;
