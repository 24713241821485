import { useContext, useEffect } from 'react';
import { AppContext } from '../data/globalstate';
import { extendTheme } from '@chakra-ui/react';
import { useApiManager } from '../domain/hooks';
import { useHygraph } from '../domain/hooks/useHygraph';

// const jsonConfig = {
// 	config: {
// 		cognitoClientId: '3n122fcl8khvplthr87odfjsfr',
// 		cognitoUserPoolId: 'us-east-1_TsZ30sPqw',
// 		useCartPage: true,
// 		useLoginPage: true,
// 		placeHolderImage: 'https://burst-pulse-spa-qa.ebbo.com/media/images/tree.png',
// 		content: {
// 			HomePage: {
// 				HeroImageBackground: {
// 					title: 'Welcome to Peak Rewards',
// 					text: 'Register for free and start earning points you can use on your next adventure.',
// 					backgroundImageSrc: '/media/images/HeaderImg_withgreen_2x.webp',
// 					joinButtonText: 'Join Now',
// 					existText: 'Already a peak rewards member?',
// 				},
// 				PageHeaderCenteredOnAccent: {
// 					logo: 'https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/no_member/Mountain_img_blue.png',
// 					heading: 'epic adventures deserve epic rewards',
// 					text: 'Gear up for your next adventure with great rewards, starting now.',
// 				},
// 				PerksThreeColumn: {},
// 				PageHeaderCentered: {},
// 				TierBenefits: {},
// 			},
// 		},
// 	},
// 	theme: {
// 		fonts: {
// 			heading: `'Open Sans', sans-serif`,
// 			body: `'Open Sans', sans-serif`,
// 			mono: `'Open Sans', sans-serif`,
// 		},
// 		colors: {
// 			primary: {
// 				50: '#62c19e',
// 				100: '#44ac86',
// 				200: '#3d9a78',
// 				300: '#36886a',
// 				400: '#2e765b',
// 				500: 'rgb(32, 81, 63)', // #20513F
// 				600: '#122c23',
// 				700: '#0a1a14',
// 				800: '#030806',
// 				900: '#000000',
// 			},
// 			primaryText: {
// 				50: '#000000',
// 				100: '#000000',
// 				200: '#000000',
// 				300: '#000000',
// 				400: '#FFFFFF',
// 				500: '#FFFFFF',
// 				600: '#FFFFFF',
// 				700: '#FFFFFF',
// 				800: '#FFFFFF',
// 				900: '#FFFFFF',
// 			},
// 			secondary: {
// 				50: '#04a3f3',
// 				100: '#0381c1',
// 				200: '#0270a8',
// 				300: '#02608e',
// 				400: '#024f75',
// 				500: 'rgb(1, 45, 67)', // #012D43
// 				600: '#000b11',
// 				700: '#000000',
// 				800: '#000000',
// 				900: '#000000',
// 			},
// 			secondaryText: {
// 				50: '#000000',
// 				100: '#000000',
// 				200: '#FFFFFF',
// 				300: '#FFFFFF',
// 				400: '#FFFFFF',
// 				500: '#FFFFFF',
// 				600: '#FFFFFF',
// 				700: '#FFFFFF',
// 				800: '#FFFFFF',
// 				900: '#FFFFFF',
// 			},
// 		},
// 	},
// };

interface IAppInitHandler {
	setLoading: React.Dispatch<boolean>;
	setThemeExtended: React.Dispatch<Record<string, unknown>>;
}

export const AppInitHandler: React.FC<IAppInitHandler> = ({ setLoading, setThemeExtended }) => {
	const { useGetSettings, useGetHomePage } = useHygraph();
	const { data: settingsData, isLoading: settingsLoading } = useGetSettings();
	const { isLoading: homePageLoading, refetch: fetchHome } = useGetHomePage();
	const { setAppConfiguration, setTiers } = useContext(AppContext);
	// const [thisLoading, setThisLoading] = useState<boolean>(true);
	const { useGetTiers } = useApiManager();
	const { isLoading: tiersLoading, data: tiersData } = useGetTiers();

	// 	console.log(window.location.host);
	// 	// TODO Get JSON config from CDN.

	// Merge Multiple loading states
	useEffect(() => {
		if (tiersLoading || settingsLoading || homePageLoading) {
			setLoading(true);
		}
		if (!tiersLoading && !settingsLoading && !homePageLoading) {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [tiersLoading, settingsLoading, homePageLoading]);

	//On Tier Data
	useEffect(() => {
		// console.log(tiersData);
		if (tiersData && tiersData.tiers !== null) {
			setTiers(tiersData.tiers);
		} else {
			setTiers(undefined);
		}
		// eslint-disable-next-line
	}, [tiersData]);

	// //On Load
	// useEffect(() => {
	// 	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	// 	if (jsonConfig.theme) {
	// 		setThemeExtended(extendTheme(jsonConfig.theme));
	// 	}

	// 	setAppConfiguration(jsonConfig.config);

	// 	// Hide Loader => App Ready
	// 	setTimeout(() => {
	// 		setThisLoading(false);
	// 	}, 1000);
	// 	// eslint-disable-next-line
	// }, []);

	//   TODO
	useEffect(() => {
		// console.log(data);
		if (settingsData) {
			if (settingsData.theme) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				setThemeExtended(extendTheme(settingsData.theme));

				//Inject Fonts
				// console.log(settingsData.theme.fonts);
				const headingFont = settingsData.theme.fonts?.heading.split(',') ?? ['Open Sans'];
				const bodyFont = settingsData.theme.fonts?.body.split(',') ?? ['Open Sans'];
				const monoFont = settingsData.theme.fonts?.mono.split(',') ?? ['Open Sans'];
				// Add Heading font
				const link1 = document.createElement('link');
				link1.rel = 'stylesheet';
				link1.href = `https://fonts.googleapis.com/css2?family=${headingFont[0].trim().replaceAll('"', '').replaceAll("'", '')}&display=swap`;
				link1.id = 'headingFont';
				// console.log(document.getElementById('headingFont'));
				if (!document.getElementById('headingFont')) {
					document.head.appendChild(link1);
				}
				// Add Body font
				if (bodyFont !== headingFont) {
					const bodyFontLink = document.createElement('link');
					bodyFontLink.rel = 'stylesheet';
					bodyFontLink.href = `https://fonts.googleapis.com/css2?family=${bodyFont[0].trim().replaceAll('"', '').replaceAll("'", '')}&display=swap`;
					bodyFontLink.id = 'bodyFont';
					if (!document.getElementById('bodyFont')) {
						document.head.appendChild(bodyFontLink);
					}
				}
				// Add Mono font
				// console.log(monoFont !== headingFont && monoFont !== bodyFont);
				if (monoFont !== headingFont && monoFont !== bodyFont) {
					const monoFontLink = document.createElement('link');
					monoFontLink.rel = 'stylesheet';
					monoFontLink.href = `https://fonts.googleapis.com/css2?family=${monoFont[0].trim().replaceAll('"', '').replaceAll("'", '')}&display=swap`;
					monoFontLink.id = 'monoFont';
					if (!document.getElementById('monoFont')) {
						document.head.appendChild(monoFontLink);
					}
				}
			}
			setAppConfiguration(settingsData);
			setLoading(true);
			setTimeout(() => {
				void fetchHome();
				setLoading(false);
			}, 500);
		}
		// eslint-disable-next-line
	}, [settingsData]);

	return null;
};
