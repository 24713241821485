import {
	Box,
	CircularProgress,
	Flex,
	Heading,
	Stack,
	useColorModeValue as mode,
	Text,
	Button,
	HStack,
	Link,
	useBreakpointValue,
	useToast,
	ButtonSpinner,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useApiManager, useCartManager } from '../../../domain/hooks';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import OrderSummaryItem from './OrderSummaryItem';
import { NavLink, useNavigate } from 'react-router-dom';
import { CartProductMeta } from '../cart/CartProductMeta';
import { AppContext } from '../../../data/globalstate';
import { ICartData, IRedeemRewardResponse } from '../../../domain/models';
import _ from 'lodash';

const OrderSummary: React.FC = () => {
	const { user, appConfiguration } = useContext(AppContext);
	const { useGetCart, useRemoveCartItem, useChangeQuantity } = useCartManager();
	const { data: cartData, error: cartError } = useGetCart();
	const { mutate: removeCartItem } = useRemoveCartItem();
	const { mutate: changeQuantity } = useChangeQuantity();
	const { useRedeemReward } = useApiManager();
	const isFetching = useIsFetching({ queryKey: ['cart'] });
	const isMutating = useIsMutating({ mutationKey: ['cart'] });
	const [loading, setLoading] = useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, md: false });
	const toast = useToast();
	const { mutateAsync: redeemReward } = useRedeemReward();
	const navigate = useNavigate();

	let cartDetails: ICartData = { items: [], subtotal: 0 };
	if (user && cartData) {
		cartDetails = cartData[user.burstId];
	}

	useEffect(() => {
		if (isFetching > 0 || isMutating > 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching, isMutating]);

	useEffect(() => {
		if (cartError) {
			console.error(cartError);
			// TODO Display
		}
	}, [cartError]);

	return (
		<Box
			maxW="7xl"
			mx="auto"
			px={{ base: '4', md: '8', lg: '12' }}
			py={{ base: '6', md: '8', lg: '12' }}
		>
			<Stack spacing={{ base: '8', md: '12' }}>
				<Heading fontSize="2xl" fontWeight="extrabold">
					Order Summary
				</Heading>
				{loading && <CircularProgress isIndeterminate alignSelf={'center'} />}
				<Stack spacing="8">
					{cartDetails.items.map((item) => {
						// const defaultImage = item.itemData.imageAlternatives?.find((v) => v.isPrimary);
						let primaryImageUrl = item.itemData.imageURL;
						let primaryAltText = item.itemData.voucherName;

						if (!primaryImageUrl) {
							const match = item.itemData.imageAlternatives?.filter((v) => {
								return v.isPrimary;
							});
							if (match) {
								primaryImageUrl = match[0]?.url;
								primaryAltText = match[0]?.altText;
							}
						}

						return isMobile ? (
							<Box key={item.itemData.rewardId}>
								<Flex>
									<NavLink to={item.itemData.rewardId ? `/product/${item.itemData.rewardId}` : '#'}>
										<CartProductMeta
											// isInStock={isInStock}
											title={item.itemData.voucherName ?? ''}
											// variants={variants}
											image={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
											altText={primaryAltText}
											// isBestSeller={isBestSeller}
										/>
									</NavLink>
									<Text pt="1" fontSize="sm" fontWeight="semibold">
										{formatPrice(item.itemData.points ?? 0, {
											locale: 'en-US',
											currency: 'POINTS',
										})}
									</Text>
								</Flex>
								<HStack mt="2" justify="space-between">
									<Flex align="center">
										<Text
											fontSize={{ base: 'sm', md: 'md' }}
											mb="0"
											color={mode('gray.600', 'gray.400')}
										>
											Qty:
										</Text>
										<Text ml={5}>{item.quantity}</Text>
									</Flex>
								</HStack>
							</Box>
						) : (
							<Flex align="flex-start" justify="space-between" key={item.itemData.rewardId}>
								<NavLink to={item.itemData.rewardId ? `/product/${item.itemData.rewardId}` : '#'}>
									<CartProductMeta
										// isInStock={isInStock}
										title={item.itemData.voucherName ?? ''}
										// variants={variants}
										image={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
										altText={primaryAltText}
										// isBestSeller={isBestSeller}
									/>
								</NavLink>
								<HStack width="full" maxW="sm" justify="space-between">
									<Flex align="center">
										<Text
											fontSize={{ base: 'sm', md: 'md' }}
											mb="0"
											color={mode('gray.600', 'gray.400')}
										>
											Qty:
										</Text>
										<Text ml={5}>{item.quantity}</Text>
									</Flex>
									<Flex
										direction="column"
										align="flex-end"
										justify="space-between"
										width="full"
										maxW="2xs"
										minH="16"
									>
										<Text fontWeight="semibold">
											{formatPrice(item.itemData.points ?? 0, {
												locale: 'en-US',
												currency: 'POINTS',
											})}
										</Text>
									</Flex>
								</HStack>
							</Flex>
						);
					})}
				</Stack>
				<Flex width="full" flexDirection="row" justifyContent="flex-end">
					<Stack spacing="6" width="full" maxW="sm">
						<Stack spacing="6" bg={mode('gray.50', 'gray.700')} rounded="lg" padding="8">
							{/* <Text fontSize="lg" fontWeight="bold">
								Order Summary
							</Text> */}

							<Stack spacing="4">
								<OrderSummaryItem
									label="Subtotal"
									value={formatPrice(cartDetails.subtotal ?? 0, {
										locale: 'en-US',
										currency: 'POINTS',
									})}
								/>
								{/* <OrderSummaryItem
									label="Shipping + Tax"
									value={formatPrice(0, { locale: 'en-US', currency: 'USD' })}
								/> */}
								{/* <OrderSummaryItem label="Coupon Code">
					<Link href="#" textDecor="underline">
						Add coupon code
					</Link>
				</OrderSummaryItem> */}
								<Flex justify="space-between" fontWeight="semibold">
									<Text>Total</Text>
									<Text>
										{formatPrice((cartDetails.subtotal ?? 0) + 0, {
											locale: 'en-US',
											currency: 'POINTS',
										})}
									</Text>
								</Flex>
							</Stack>
						</Stack>
						<Button
							colorScheme="secondary"
							size="md"
							isDisabled={
								cartDetails.items.length === 0 || (cartDetails.subtotal ?? 0) > (user?.balance ?? 0)
							}
							disabled={
								cartDetails.items.length === 0 || (cartDetails.subtotal ?? 0) > (user?.balance ?? 0)
							}
							onClick={() => {
								// console.log('Redeem Cart!', cartData);
								setLoading(true);

								if (!user) {
									toast({
										description: `You must be logged in to redeem!`,
										status: 'error',
										duration: 5000,
										isClosable: true,
									});
									setLoading(false);
									return;
								}
								if (cartDetails.items.length <= 0) {
									toast({
										description: `No Items to Redeem!`,
										status: 'error',
										duration: 5000,
										isClosable: true,
									});
									setLoading(false);
									return;
								}
								const promises: Promise<IRedeemRewardResponse>[] = [];
								cartDetails.items.forEach((item) => {
									// console.log(item);
									if (item.quantity > 1) {
										for (let i = 0; i < item.quantity; i++) {
											promises.push(
												redeemReward({
													userId: user.burstId,
													offerType: item.itemData.offerType ?? 'POINTREWARD',
													rewardType: item.itemData.rewardId,
													source: 'API',
												}).then((v) => {
													// console.log(item);
													// console.log(v);

													if (i + 1 === item.quantity) {
														// Remove Item
														removeCartItem(item);
													} else {
														// Reduce Qty Value
														changeQuantity({
															item: item,
															quantity: item.quantity - 1,
														});
													}

													toast({
														title: `Redemption Success!`,
														description: item.itemData.voucherName ?? 'Unknown',
														status: 'success',
														duration: 5000,
														isClosable: true,
													});

													return v;
												}),
											);
										}
									} else if (item.quantity === 1) {
										promises.push(
											redeemReward({
												userId: user.burstId,
												offerType: item.itemData.offerType ?? 'POINTREWARD',
												rewardType: item.itemData.rewardId,
												source: 'API',
											}).then((v) => {
												// console.log(item);
												// console.log(v);

												// Remove Item
												removeCartItem(item);

												toast({
													title: `Redemption Success!`,
													description: item.itemData.voucherName ?? 'Unknown',
													status: 'success',
													duration: 5000,
													isClosable: true,
												});

												return v;
											}),
										);
									}
									// Else Ignore the record
								});
								Promise.all(promises)
									.then((v) => {
										// console.log(v);
										if (_.every(v, { isSuccess: true })) {
											toast({
												description: `Your items have been redeemed!`,
												status: 'success',
												duration: 5000,
												isClosable: true,
											});
											navigate('/order-confirmation');
										}
									})
									.catch((e: unknown) => {
										// console.error(e);
										toast({
											title: `Redemption Error!`,
											description: JSON.stringify(e),
											status: 'error',
											duration: 5000,
											isClosable: true,
										});
									})
									.finally(() => {
										setLoading(false);
									});
							}}
						>
							{(cartDetails.subtotal ?? 0) > (user?.balance ?? 0) ? 'Not Enough Points' : 'Redeem'}
							{loading && <ButtonSpinner />}
						</Button>
						<HStack mt="6" fontWeight="semibold">
							<p>or</p>
							<Link as={NavLink} to={'/rewards'} color={mode('secondary.500', 'secondary.200')}>
								Continue shopping
							</Link>
						</HStack>
					</Stack>
				</Flex>
			</Stack>
		</Box>
	);
};
export default OrderSummary;
