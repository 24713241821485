import { Button, HStack, Icon, Link, Skeleton, Text, useToken, VStack } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../data/globalstate';
import { IoDiamondOutline } from 'react-icons/io5';
import { useApiManager } from '../../../domain/hooks';
import { isNumber } from 'lodash';
import { TierTracker } from './TierTracker';
import { PiUserCircleFill } from 'react-icons/pi';

const UserBar: React.FC = () => {
	const { user, setUser, tiers } = useContext(AppContext);
	const navigate = useNavigate();
	const { useGetMemberBalance } = useApiManager();
	const { data: balanceData, isLoading: balanceLoading } = useGetMemberBalance({
		membershipCode: user?.burstId,
	});
	const tertiaryColor = useToken('colors', 'tertiary.500');

	//Hook balance data to update app context to make balance available globally without additional calls.
	useEffect(() => {
		// console.log(balanceData);
		if (user && isNumber(balanceData?.balance.balance)) {
			user.balance = balanceData.balance.balance;
			// console.log('Update User', user);
			setUser(user);
		}
		// eslint-disable-next-line
	}, [balanceData, user]);

	if (!user) {
		return null;
	}

	return (
		<HStack
			// TODO THis should be a theme token
			bg={'#e7d5d1'}
			justifyContent={'center'}
			alignSelf={'stretch'}
		>
			<HStack maxW={'7xl'} justifyContent={'space-between'} flex={1}>
				<HStack color={'secondary.500'}>
					{/* <Avatar /> */}
					<Icon as={PiUserCircleFill} w={'48px'} h={'48px'} />
					<VStack justifyContent={'flex-start'} alignItems={'flex-start'} gap={0}>
						<Text>
							Welcome Back, <span>{user.burstProfile.firstName}</span>
						</Text>
						<Link
							textTransform={'uppercase'}
							textDecoration={'underline'}
							onClick={() => {
								navigate('/profile');
							}}
						>
							view account
						</Link>
					</VStack>
					<Skeleton isLoaded={!balanceLoading}>
						<Button
							leftIcon={<IoDiamondOutline />}
							colorScheme="secondary"
							// color={'white'}
							// backgroundColor={'secondary.500'}
							ml={10}
							isDisabled
							// _hover={{
							// 	color: 'white',
							// 	backgroundColor: 'secondary.500',
							// }}
							_disabled={{
								// 	color: 'white',
								backgroundColor: 'secondary.500',
							}}
						>
							Points Balance:&nbsp;
							<span style={{ color: tertiaryColor }}>
								{balanceData?.balance.balance.toLocaleString('en-US') ?? 0}
							</span>
						</Button>
					</Skeleton>
				</HStack>
				{tiers && tiers.length > 0 && (
					<TierTracker balanceLoading={balanceLoading} balanceData={balanceData} />
				)}
			</HStack>
		</HStack>
	);
};
export default UserBar;
