import React from 'react';
import { Button, Heading, Img, Text, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const HowItWorksTrail: React.FC = () => {
	return (
		<VStack
			my={{ base: 0, xl: '50px' }}
			justifyContent={'center'}
			alignItems={'center'}
			color={'black'}
			alignSelf={{ base: 'center', xl: 'stretch' }}
			w={'740px'}
			minW={'740px'}
			maxW={'740px'}
		>
			<VStack id="step1" justifyContent={'center'} alignItems={'center'} gap={0} maxW={'180px'}>
				<Img src="/media/images/step1_map_icon.svg" alt="Step 1" width={'71px'} height={'102px'} />
				<Heading
					as="h2"
					fontSize="large"
					fontWeight={'bold'}
					textTransform="uppercase"
					marginTop={'15px'}
					marginBottom={'10px'}
				>
					SIGN UP
				</Heading>
				<Text fontSize={'16px'} mb={'15px'}>
					Register for free and start earning points.
				</Text>
				<Button
					as={NavLink}
					to={'/join'}
					colorScheme="primary"
					size="md"
					textTransform={'uppercase'}
					textColor={'white'}
					mx={'20px'}
				>
					Create An Account
				</Button>
			</VStack>
			<Img
				src="/media/images/vdottedline_1to2.svg"
				alt="Trail from Step 1 to 2"
				width={'443px'}
				height={'234px'}
				marginTop={'-20px'}
			/>
			<VStack
				id="step2"
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				alignSelf={'flex-start'}
				maxW={'310px'}
			>
				<Img
					src="/media/images/step2_map_icon.svg"
					alt="Step 2"
					width={'71px'}
					height={'102px'}
					marginX={'10px'}
				/>
				<Heading
					as="h2"
					fontSize="large"
					fontWeight={'bold'}
					textTransform="uppercase"
					marginTop={'15px'}
					marginBottom={'10px'}
				>
					EARN POINTS
				</Heading>
				<Text fontSize={'16px'} mb={'15px'}>
					Earn points for gearing up, then get even more for completing activities.
				</Text>
				<Button
					as={NavLink}
					to={'/earn-points'}
					colorScheme="primary"
					size="md"
					textTransform={'uppercase'}
					textColor={'white'}
					mx={'20px'}
				>
					Start Earning
				</Button>
			</VStack>
			<Img
				src="/media/images/vdottedline_2to3.svg"
				alt="Trail from Step 2 to 3"
				width={'443px'}
				height={'234px'}
				marginTop={'-20px'}
				alignSelf={'flex-start'}
				ml={-6}
			/>
			<VStack id="step3" justifyContent={'center'} alignItems={'center'} maxW={'300px'}>
				<Img
					src="/media/images/step3_map_icon.svg"
					alt="Step 3"
					width={'71px'}
					height={'102px'}
					marginX={'10px'}
				/>
				<Heading
					as="h2"
					fontSize=""
					fontWeight={'bold'}
					textTransform="uppercase"
					marginTop={'15px'}
					marginBottom={'10px'}
				>
					GET REWARDED
				</Heading>
				<Text fontSize={'16px'} mb="15px">
					Redeem your points for rewards like gift cards, accessories, swag and chances to win
					exciting prizes.
				</Text>
				<Button
					as={NavLink}
					to={'/rewards'}
					colorScheme="primary"
					size="md"
					textTransform={'uppercase'}
					textColor={'white'}
					mx={'20px'}
				>
					Shop Rewards Catalog
				</Button>
			</VStack>
		</VStack>
	);
};

export default HowItWorksTrail;
