import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import { ScrollRestoration, useParams } from 'react-router-dom';
import ProductDetailBlock from '../components/products/ProductDetailBlock';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';

const ProductDetailPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const params = useParams();

	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Product Detail" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<ProductDetailBlock productId={params.productId} />
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedProductDetailPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <ProductDetailPage ref={ref} />),
);
export default ProductDetailPage;
