import { HStack, Flex, Button, Image, Link, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import AuthModal from './authentication/AuthModal';
import { AppContext } from '../../data/globalstate';
import SignInButton from './authentication/SignInButton';
import UserBar from './user/UserBar';

const demoLinksLoggedOut = [
	{
		href: '/',
		text: 'home',
		imageUrl:
			'https://burst-staging.ebbo.com/media/logo/stores/8/PinnacleOutdoor_header_logo_2x.png',
	},
	{
		href: '/rewards',
		text: 'redeem rewards',
	},
	{
		href: '/earn-points',
		text: 'earn points',
	},
	{
		href: '/how-it-works',
		text: 'how it works',
	},
];
const demoLinksLoggedIn = [
	{
		href: '/earn-points',
		text: 'earn points',
	},
	{
		href: '/rewards',
		text: 'redeem rewards',
	},
	{
		href: '/',
		text: 'home',
		imageUrl:
			'https://burst-staging.ebbo.com/media/logo/stores/8/PinnacleOutdoor_header_logo_2x.png',
	},
	{
		href: '/how-it-works',
		text: 'how it works',
	},
	{
		href: '/contact-us',
		text: 'contact us',
	},
];

const NavigationBar: React.FC = () => {
	const { user } = useContext(AppContext);
	const location = useLocation();
	// console.log(location);

	if (!user) {
		return (
			<HStack
				bg={'white'}
				// h={'116px'}
				minH={'100px'}
				justifyContent={'center'}
				// px={'20px'}
				// spacing={'20px'}
				role="navigation"
			>
				<HStack maxW={'7xl'} justifyContent={'space-between'} flex={1}>
					{demoLinksLoggedOut.map((v, i) => {
						if (v.imageUrl) {
							return (
								<Link
									// as={<NavLink className={({isActive})} />}
									as={NavLink}
									textTransform={'uppercase'}
									color={'primary.500'}
									// href={v.href}
									to={v.href}
									key={`navlink_${i.toString()}`}
									// className={({ isActive }) => {}}
									// _active={{ fontWeight: 'bold' }}
									fontWeight={location.pathname === v.href ? 'bold' : undefined}
								>
									<Image src={v.imageUrl} alt={v.text} fit={'contain'} w={'134px'} />
								</Link>
							);
						} else {
							return (
								<Link
									// as={<NavLink className={({isActive})} />}
									as={NavLink}
									textTransform={'uppercase'}
									color={'primary.500'}
									// href={v.href}
									to={v.href}
									key={`navlink_${i.toString()}`}
									// className={({ isActive }) => {}}
									// _active={{ fontWeight: 'bold' }}
									fontWeight={location.pathname === v.href ? 'bold' : undefined}
								>
									{v.text}
								</Link>
							);
						}
					})}
					<Flex justifyContent={'flex-end'}>
						<Button
							as={NavLink}
							to={'/join'}
							colorScheme="secondary"
							size="lg"
							textTransform={'uppercase'}
							textColor={'white'}
							mx={'20px'}
						>
							Join
						</Button>
						<SignInButton
							colorScheme="primary"
							size="lg"
							textTransform={'uppercase'}
							textColor={'white'}
						/>
					</Flex>
					{/* {user && (
				<Flex justifyContent={'flex-end'} alignItems={'center'}>
					<Text color={'black'} px={'10px'}>
						Hi, {user.burstProfile.firstName}
					</Text>
					<Button
						colorScheme="primary"
						size="lg"
						textTransform={'uppercase'}
						textColor={'white'}
						onClick={() => {
							// Logout
							user.cognitoUser.signOut();
							expireCookie('accessToken');
							setUser(undefined);
							navigate('/');
						}}
					>
						Log Out
					</Button>
				</Flex>
			)} */}
				</HStack>
				<AuthModal />
			</HStack>
		);
	} else {
		return (
			<VStack gap={0}>
				<HStack
					bg={'white'}
					// h={'116px'}
					minH={'100px'}
					justifyContent={'center'}
					// px={'20px'}
					// spacing={'20px'}
					role="navigation"
					alignSelf={'stretch'}
					boxShadow={'0px 3px 3px 0px rgba(0,0,0,0.75);'}
					zIndex={1}
				>
					<HStack maxW={'7xl'} justifyContent={'space-between'} flex={1}>
						{demoLinksLoggedIn.map((v, i) => {
							if (v.imageUrl) {
								return (
									<Link
										// as={<NavLink className={({isActive})} />}
										as={NavLink}
										textTransform={'uppercase'}
										color={'primary.500'}
										// href={v.href}
										to={v.href}
										key={`navlink_${i.toString()}`}
										// className={({ isActive }) => {}}
										// _active={{ fontWeight: 'bold' }}
										fontWeight={location.pathname === v.href ? 'bold' : undefined}
									>
										<Image src={v.imageUrl} alt={v.text} fit={'contain'} w={'134px'} />
									</Link>
								);
							} else {
								return (
									<Link
										// as={<NavLink className={({isActive})} />}
										as={NavLink}
										textTransform={'uppercase'}
										color={'primary.500'}
										// href={v.href}
										to={v.href}
										key={`navlink_${i.toString()}`}
										// className={({ isActive }) => {}}
										// _active={{ fontWeight: 'bold' }}
										fontWeight={location.pathname === v.href ? 'bold' : undefined}
									>
										{v.text}
									</Link>
								);
							}
						})}
					</HStack>
				</HStack>
				<UserBar />
			</VStack>
		);
	}
};
export default NavigationBar;
