import { Button, HStack } from '@chakra-ui/react';

export interface ProductFilterActionButtonsProps {
	onClickCancel?: VoidFunction;
	isCancelDisabled?: boolean;
	onClickApply?: VoidFunction;
	onClickReset?: VoidFunction;
	showReset?: boolean;
}

export const ProductFilterActionButtons: React.FC<ProductFilterActionButtonsProps> = (props) => {
	const { onClickApply, onClickCancel, isCancelDisabled, showReset, onClickReset } = props;
	return (
		<HStack spacing="2" justify="space-between">
			{showReset && (
				<Button size="sm" variant="tertiary" onClick={onClickReset}>
					Clear
				</Button>
			)}
			<Button size="sm" variant="tertiary" onClick={onClickCancel} isDisabled={isCancelDisabled}>
				Cancel
			</Button>
			<Button size="sm" colorScheme="secondary" onClick={onClickApply}>
				Save
			</Button>
		</HStack>
	);
};
