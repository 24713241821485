export enum EPromotionType {
	Poll = 0,
	Quiz = 1,
	Profile = 2,
	Survey = 3,
	AcceptedReferral = 4,
	ProductReview = 5,
	Anniversary = 6,
	Purchase = 7,
	Video = 8,
	OptIn = 9,
}
