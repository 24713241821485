import { Select, SelectProps, useColorModeValue } from '@chakra-ui/react';

const sortByOptions = {
	defaultValue: 'best-seller',
	options: [
		{ label: 'Newest', value: 'CreatedOn-desc' },
		{ label: 'Price: Low to High', value: 'Point-asc' },
		{ label: 'Price: High to Low', value: 'Point-desc' },
		{ label: 'Name: A to Z', value: 'VoucherTypeName-asc' },
		{ label: 'Name: Z to A', value: 'VoucherTypeName-desc' },
	],
};

const ProductSortbySelect: React.FC<SelectProps> = (props) => (
	<Select
		size="sm"
		aria-label="Sort by"
		defaultValue={sortByOptions.defaultValue}
		focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
		rounded="md"
		{...props}
	>
		{sortByOptions.options.map((option) => (
			<option key={option.value} value={option.value}>
				{option.label}
			</option>
		))}
	</Select>
);
export default ProductSortbySelect;
