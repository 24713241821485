import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Img,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	ToastId,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { AppContext } from '../../../data/globalstate';
import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { useApiManager, useAuthManager } from '../../../domain/hooks';
import { AuthValidationScheme, IAuthForm } from './authModels';

const AuthModal: React.FC = () => {
	const { showAuth, setShowAuth, setUser } = useContext(AppContext);
	const { isOpen: showPassword, onToggle: onToggleShowPassword } = useDisclosure();
	const navigate = useNavigate();
	const toast = useToast();
	const toastIdRef = React.useRef<ToastId>();
	const [loading, setLoading] = useState<boolean>(false);
	const { useLogin: authLogin } = useAuthManager();
	const { useLogin: apiLogin } = useApiManager();
	const { mutateAsync: doAuthLogin } = authLogin();
	const { mutateAsync: doApiLogin } = apiLogin();

	const formik = useFormik<IAuthForm>({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: AuthValidationScheme,
		onSubmit: (values) => {
			if (values.email && values.password) {
				setLoading(true);
				toastIdRef.current = toast({
					title: 'Logging In...',
					status: 'loading',
					id: 'login',
					duration: null,
				});
				doAuthLogin({ userName: values.email, password: values.password })
					.then((res) => {
						// console.log('Login Successful');
						doApiLogin({ accountNumber: res.burstId })
							.then((v) => {
								// console.log(v);
								setUser({
									cognitoUser: res.cognitoUser,
									burstId: res.burstId,
									burstProfile: v,
								});

								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Login Successful!',
										status: 'success',
										isClosable: false,
										duration: 500,
									});
								}
								setLoading(false);
								setShowAuth(false);
								formik.resetForm();
							})
							.catch((e: unknown) => {
								if (toastIdRef.current) {
									toast.update(toastIdRef.current, {
										title: 'Profile Error',
										description: JSON.stringify(e),
										status: 'error',
										isClosable: true,
										duration: null,
									});
								}
								setLoading(false);
							});
					})
					.catch((e: unknown) => {
						console.error('Login Error');
						if (toastIdRef.current) {
							toast.update(toastIdRef.current, {
								title: 'Login Error',
								description: (e as Error).message || JSON.stringify(e),
								status: 'error',
								isClosable: true,
								duration: null,
							});
						}
						setLoading(false);
					});
			}
		},
	});
	// console.log(formik.errors);
	return (
		<Modal
			isOpen={showAuth}
			onClose={() => {
				formik.resetForm();
				setShowAuth(false);
			}}
			size={'lg'}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={'center'} color={'primary.500'}>
					SIGN IN
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody p={0}>
					<Stack spacing="8" px={12} pb={6} position={'relative'}>
						<Stack spacing={{ base: '2', md: '3' }} zIndex={1} pt={2}>
							<Heading size={{ base: 'sm' }} color={'secondary.500'} textAlign={'center'}>
								Welcome back to Peak Rewards!
							</Heading>
							<Stack spacing="5">
								<VStack gap={1}>
									<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
										<FormLabel htmlFor="email">Email</FormLabel>
										<Input
											id="email"
											type="email"
											name="email"
											required
											value={formik.values.email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											backgroundColor={'white'}
										/>
										{formik.errors.email && (
											<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
										<FormLabel htmlFor="password">Password</FormLabel>
										<InputGroup>
											<InputRightElement>
												<IconButton
													variant="text"
													aria-label={showPassword ? 'Mask password' : 'Reveal password'}
													icon={showPassword ? <HiEyeOff /> : <HiEye />}
													onClick={onToggleShowPassword}
												/>
											</InputRightElement>
											<Input
												id="password"
												name="password"
												type={showPassword ? 'text' : 'password'}
												autoComplete="current-password"
												required
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												backgroundColor={'white'}
											/>
										</InputGroup>
										{formik.errors.password && (
											<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
										)}
									</FormControl>
								</VStack>
								<Button
									colorScheme="secondary"
									mx={'20%'}
									textTransform={'uppercase'}
									onClick={() => {
										toast.closeAll();
										formik.handleSubmit();
									}}
									isLoading={loading}
								>
									Sign in
								</Button>
								<Link
									as={NavLink}
									to={'/forgot-password'}
									variant="text"
									size="sm"
									fontSize={{ base: 'sm' }}
									color={'secondary.500'}
									textDecoration={'underline'}
								>
									Forgot Your Password?
								</Link>
							</Stack>
						</Stack>
						<Flex
							id="image-wrapper"
							position="absolute"
							insetX="0"
							insetY="0"
							w="full"
							h="full"
							overflow="hidden"
							align="center"
							bg={'white'}
						>
							<Box position="relative" w="full" h="full">
								<Img
									src={'/media/images/Footer_Img.webp'}
									alt="Footer Background"
									w="full"
									h="full"
									objectFit="cover"
									objectPosition="top bottom"
									position="absolute"
								/>
							</Box>
						</Flex>
					</Stack>
					<VStack
						justifyContent={'center'}
						alignItems={'center'}
						backgroundColor={'tertiary.200'}
						py={4}
						borderBottomRadius={'lg'}
					>
						<Text fontWeight={'bold'} color={'primary.500'}>
							DON&rsquo;T HAVE AN ACCOUNT?
						</Text>
						<HStack>
							<Link
								variant="text"
								onClick={() => {
									formik.resetForm();
									setShowAuth(false);
									navigate('/join');
								}}
								textDecoration={'underline'}
								color={'primary.500'}
							>
								Register now
							</Link>
							<Text color={'secondary.500'}> for Peak Rewards today!</Text>
						</HStack>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default AuthModal;
