export const CircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			{...props}
			// stroke="#d49283"
			// fill="white"
			// stroke-width="1px"
			viewBox="0 0 100 100"
			// height="1em"
			// width="1em"
			xmlns="http://www.w3.org/2000/svg"
		>
			{/* <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path> */}
			<circle
				cx="50"
				cy="50"
				r="40"
				stroke={props.stroke}
				strokeWidth={props.strokeWidth}
				fill={props.fill}
			/>
		</svg>
	);
};
