import { HStack, InputProps, Select } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

/**
 * Calculate the days in a given month.
 *
 * @param month The Month Number 1-12
 * @param year The Year
 * @returns Array for the Days in the month as numbers.
 */
const daysInMonth = (month: number, year: number): number[] => {
	const days: number[] = [];
	const numDays = new Date(year, month, 0).getDate();

	for (let i = 1; i <= numDays; i++) {
		days.push(i);
	}

	return days;
};

/**
 * Generate Years List
 *
 * @param minYear Starting Year
 * @param maxYear Ending Year
 * @returns Array of Years from starting year to ending year as numbers.
 */
const generateYearOptions = (minYear: number, maxYear: number): number[] => {
	const years: number[] = [];

	for (let i = minYear; i <= maxYear; i++) {
		years.push(i);
	}

	return years;
};

/**
 * Generate the default month options.
 *
 * @returns Default Month Options 1-12 as numbers.
 */
const generateMonthOptions = (): number[] => {
	const months: number[] = [];

	for (let i = 0; i < 12; i++) {
		months.push(i);
	}

	return months;
};

/**
 * Generate a default set of days.
 *
 * @returns Default Days 1-30 as numbers.
 */
const defaultDayOptions = (): number[] => {
	const days: number[] = [];

	for (let i = 1; i <= 30; i++) {
		days.push(i);
	}

	return days;
};

/**
 * Date of Birth Input Props
 */
interface DOBInputProps extends Omit<InputProps, 'value' | 'onChange'> {
	/**
	 * The Chosen Date Value
	 */
	value?: Date;
	/**
	 * The Starting Year
	 */
	minYear: number;
	/**
	 * The Ending Year
	 */
	maxYear: number;
	/**
	 * On Date Value Changed
	 *
	 * @param value The Selected Date
	 * @returns
	 */
	onChange?: (value: Date) => void;
	/**
	 * Show month name instead of number.
	 */
	showMonthName?: boolean;
	/**
	 * Show the day picker.
	 */
	showDayPicker?: boolean;
}

const DateOfBirthInput: React.FC<DOBInputProps> = (props) => {
	// console.log('DateOfBirthInput', props);
	// console.log('Generate Years', generateYearOptions(props.minYear, props.maxYear));
	const [dayOptions, setDayOptions] = useState<number[]>(defaultDayOptions());
	const [chosenMonth, setChosenMonth] = useState<number>();
	const [chosenDay, setChosenDay] = useState<number | undefined>(
		props.showDayPicker ? undefined : 1,
	);
	const [chosenYear, setChosenYear] = useState<number>();

	const yearOptions = useMemo(
		() => generateYearOptions(props.minYear, props.maxYear),
		[props.minYear, props.maxYear],
	);

	const monthOptions = useMemo(() => generateMonthOptions(), []);

	useEffect(() => {
		// console.log('Chosen Month:', chosenMonth);
		// console.log('Chosen Day:', chosenDay);
		// console.log('Chosen Year:', chosenYear);
		if (chosenDay && chosenMonth && chosenYear) {
			const newDate = new Date();
			newDate.setFullYear(chosenYear, chosenMonth - 1, chosenDay);

			// console.log('New BDay:', newDate);

			if (props.onChange !== undefined) {
				// props.onChange(e);
				props.onChange(newDate);
			}
		}
		if (chosenMonth && chosenYear) {
			setDayOptions(daysInMonth(chosenMonth, chosenYear));
		}
		// eslint-disable-next-line
	}, [chosenMonth, chosenDay, chosenYear]);

	return (
		<HStack gap={0}>
			<Select
				name="dateOfBirthYear"
				id="dateOfBirthYear"
				placeholder="Year"
				borderRightRadius={0}
				onChange={(e) => {
					// console.log('Year Changed:', e);
					// console.log(e.target.value);
					if (e.target.value.length > 0) {
						setChosenYear(parseInt(e.target.value));
					} else {
						setChosenYear(undefined);
					}
				}}
				value={chosenYear}
			>
				{yearOptions.map((v, i) => {
					return (
						<option key={`yearOption_${i.toString()}`} value={v}>
							{v}
						</option>
					);
				})}
			</Select>
			<Select
				name="dateOfBirthMonth"
				id="dateOfBirthMonth"
				placeholder="Month"
				borderRadius={0}
				value={chosenMonth}
				onChange={(e) => {
					// console.log('Month Changed:', e);
					// console.log(e.target.value);
					if (e.target.value.length > 0) {
						setChosenMonth(parseInt(e.target.value));
					} else {
						setChosenMonth(undefined);
					}
				}}
			>
				{monthOptions.map((v, i) => {
					return (
						<option key={`monthOption_${i.toString()}`} value={v}>
							{props.showMonthName
								? new Date(1900, v, 1).toLocaleDateString('en-US', { month: 'long' })
								: v}
						</option>
					);
				})}
			</Select>
			{props.showDayPicker && (
				<Select
					name="dateOfBirthDay"
					id="dateOfBirthDay"
					placeholder="Day"
					borderLeftRadius={0}
					value={chosenDay}
					onChange={(e) => {
						// console.log('Day Changed:', e);
						// console.log(e.target.value);
						if (e.target.value.length > 0) {
							setChosenDay(parseInt(e.target.value));
						} else {
							setChosenDay(undefined);
						}
					}}
				>
					{dayOptions.map((v, i) => {
						return (
							<option key={`dayOption_${i.toString()}`} value={v}>
								{v}
							</option>
						);
					})}
				</Select>
			)}
		</HStack>
	);
};
export default DateOfBirthInput;
