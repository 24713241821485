import {
	AspectRatio,
	HStack,
	Image,
	Skeleton,
	Stack,
	StackProps,
	useBreakpointValue,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../internal/carousel';
import { IImageDto, IRewardTypeDto } from '../../../domain/models';
import { AppContext } from '../../../data/globalstate';

interface GalleryProps {
	// images: IImageDto[];
	product?: IRewardTypeDto;
	aspectRatio?: number;
	rootProps?: StackProps;
}

const buildImageList = (product: IRewardTypeDto): IImageDto[] => {
	const images: IImageDto[] = [];

	if (product.imageURL) {
		images.push({
			url: product.imageURL,
			altText: product.voucherName,
			isPrimary: true,
			name: null,
		});
	}
	if (product.imageSecondary) {
		images.push({
			url: product.imageSecondary,
			altText: product.voucherName,
			isPrimary: true,
			name: null,
		});
	}
	product.imageAlternatives?.forEach((v) => {
		images.push(v);
	});

	return images;
};

const ProductImageGallery: React.FC<GalleryProps> = (props) => {
	const { product, aspectRatio = 4 / 3, rootProps } = props;
	const { appConfiguration } = useContext(AppContext);
	const [images, setImages] = useState<IImageDto[]>(product ? buildImageList(product) : []);
	// const [index, setIndex] = useState(0);
	const [currentSlide, setCurrentSlide] = useState(0);
	const slidesPerView = useBreakpointValue({ base: 3, md: 5 });

	useEffect(() => {
		if (product) {
			setImages(buildImageList(product));
		}
	}, [product]);

	// useEffect(() => {
	// 	if (images.length > 0) {
	// 		setCurrentSlide(0);
	// 	}
	// }, [images]);

	const [ref] = useCarousel({
		slides: {
			perView: slidesPerView,
			spacing: useBreakpointValue({ base: 16, md: 24 }),
		},
		// slideChanged: (slider) => {
		// 	console.log('slideChanged', slider);
		// 	setCurrentSlide(slider.track.details.rel);
		// },
	});

	return (
		<Stack spacing="4" {...rootProps}>
			<AspectRatio ratio={aspectRatio}>
				<Image
					src={images[currentSlide]?.url ?? appConfiguration?.placeHolderImage ?? undefined}
					objectFit="cover"
					alt={images[currentSlide]?.altText ?? 'Unknown'}
					fallback={<Skeleton />}
				/>
			</AspectRatio>
			{images.length > 1 && (
				<HStack spacing="4">
					<CarouselIconButton
						onClick={() => {
							// console.log('Previous Clicked!', slider);
							// slider.current?.prev(); //REVIEW Why does this not work?
							if (currentSlide === 0) {
								setCurrentSlide(images.length - 1);
							} else {
								setCurrentSlide(currentSlide - 1);
							}
						}}
						icon={<IoChevronBackOutline />}
						aria-label="Previous slide"
						disabled={currentSlide === 0}
						ml={2}
					/>
					<Carousel ref={ref} direction="row" width="full">
						{images.map((image, i) => (
							<CarouselSlide
								key={i}
								onClick={() => {
									// setIndex(i);
									setCurrentSlide(i);
								}}
								cursor="pointer"
							>
								<AspectRatio
									ratio={aspectRatio}
									transition="all 200ms"
									opacity={currentSlide === i ? 1 : 0.4}
									_hover={{ opacity: 1 }}
								>
									<Image
										src={image.url ?? undefined}
										objectFit="cover"
										alt={image.altText ?? 'Unknown'}
										fallback={<Skeleton />}
									/>
								</AspectRatio>
							</CarouselSlide>
						))}
					</Carousel>
					<CarouselIconButton
						// onClick={() => slider.current?.next()}
						onClick={() => {
							// console.log('Next Clicked!', slider);
							// if (slider.current?.track.details.rel) {
							// 	console.log(slider.current.track.details.rel);
							// 	if (slider.current.track.details.rel < images.length - 1) {
							// 		setCurrentSlide(slider.current.track.details.rel + 1);
							// 	} else {
							// 		setCurrentSlide(0);
							// 	}
							// }
							// slider.current?.next(); //REVIEW Why does this not work?

							if (currentSlide < images.length - 1) {
								setCurrentSlide(currentSlide + 1);
							} else {
								setCurrentSlide(0);
							}
						}}
						icon={<IoChevronForwardOutline />}
						aria-label="Next slide"
						disabled={currentSlide + Number(slidesPerView) === images.length}
					/>
				</HStack>
			)}
		</Stack>
	);
};
export default ProductImageGallery;
