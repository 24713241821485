import {
	Box,
	Button,
	CircularProgress,
	Drawer,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Heading,
	HStack,
	Icon,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { FiPackage } from 'react-icons/fi';
import CartItem from './CartItem';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useCartManager } from '../../../domain/hooks';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { formatPrice } from '../../../domain/utils/PriceUtils';
import { useNavigate } from 'react-router-dom';
import { ICartData } from '../../../domain/models';

const CartDrawer: React.FC = () => {
	const { setShowCart, showCart, user } = useContext(AppContext);
	const { useGetCart, useRemoveCartItem, useChangeQuantity } = useCartManager();
	const { data: cartData, error: cartError, refetch } = useGetCart();
	const { mutateAsync: removeCartItem } = useRemoveCartItem();
	const { mutateAsync: changeQuantity } = useChangeQuantity();
	const isFetching = useIsFetching({ queryKey: ['cart'] });
	const isMutating = useIsMutating({ mutationKey: ['cart'] });
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	let cartDetails: ICartData = { items: [], subtotal: 0 };
	if (user && cartData) {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (cartData[user.burstId]) {
			cartDetails = cartData[user.burstId];
		}
	}

	// console.log(cartDetails);

	const numItems = cartDetails.items.length;
	const subtotal = cartDetails.subtotal;

	useEffect(() => {
		void refetch();
	}, [user]);

	useEffect(() => {
		if (isFetching > 0 || isMutating > 0) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching, isMutating]);

	useEffect(() => {
		if (cartError) {
			console.error(cartError);
			// TODO Display
		}
	}, [cartError]);

	return (
		<Box height="100vh" position={'absolute'} top={0} right={0}>
			<Drawer
				isOpen={showCart}
				onClose={() => {
					setShowCart(false);
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={useColorModeValue('white', 'gray.800')} overflowY="auto">
					<DrawerCloseButton size="lg" right={{ base: '4', md: '8' }} top="4" bg="inherit" />
					<Stack padding={{ base: '6', md: '10' }} height="full" spacing="8" overflowY="auto">
						<Heading size="md">Shopping Cart ({cartDetails.items.length})</Heading>
						{loading && <CircularProgress isIndeterminate alignSelf={'center'} />}
						<Stack spacing={{ base: '8', md: '10' }}>
							{cartDetails.items.map((item) => (
								<CartItem
									key={item.itemData.rewardId}
									{...item}
									onClickDelete={() => {
										removeCartItem(item).catch((e: unknown) => {
											// TODO Show
											console.log(e);
										});
									}}
									onChangeQuantity={(qty) => {
										// console.log('onChangeQuantity', qty);
										changeQuantity({ item: item, quantity: qty }).catch((e: unknown) => {
											// TODO Show
											console.log(e);
										});
									}}
								/>
							))}
						</Stack>
					</Stack>
					<Stack borderTopWidth="1px" px={{ base: '6', md: '10' }} py="4" spacing="5">
						<Stack>
							<HStack fontSize="xl" fontWeight="semibold">
								<Text flex="1">Subtotal:</Text>
								<Text>
									{formatPrice(cartDetails.subtotal ?? 0, { locale: 'en-US', currency: 'POINTS' })}
								</Text>
							</HStack>
							<HStack spacing="2" color={useColorModeValue('gray.600', 'gray.400')}>
								<Icon as={FiPackage} />
								<Text>Shipping + taxes calculated at checkout</Text>
							</HStack>
						</Stack>
						<Button
							colorScheme="secondary"
							size="md"
							isDisabled={numItems === 0 || !numItems || (subtotal ?? 0) > (user?.balance ?? 0)}
							disabled={numItems === 0 || !numItems || (subtotal ?? 0) > (user?.balance ?? 0)}
							onClick={() => {
								setShowCart(false);
								navigate('/checkout');
							}}
						>
							{(subtotal ?? 0) > (user?.balance ?? 0) ? 'Not Enough Points' : 'Checkout'}
						</Button>
					</Stack>
				</DrawerContent>
			</Drawer>
		</Box>
	);
};
export default CartDrawer;
