import { useState } from 'react';
import {
	Button,
	Link,
	List,
	ListIcon,
	ListItem,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Text,
	HStack,
	VStack,
	Heading,
} from '@chakra-ui/react';
import { BiMessageAltDetail } from 'react-icons/bi';
import { isEven } from '../../../domain/utils/GeneralUtils';

const sampleData = [
	{ date: '09/13/2023', email: 'trisha.adamik@gmail.com', registered: true, earned: 100 },
	{ date: '09/13/2023', email: 'calbrycht@ebbo.com', registered: false, earned: null },
	{ date: '09/13/2023', email: 'tom.smith@hotmail.com', registered: true, earned: 100 },
];

const AccountHistoryReferral = () => {
	const [showShareLinks, setShowShareLinks] = useState<boolean>(false);
	// TODO Connect to API
	// TODO Share Link functionality
	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
			alignItems={'flex-start'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				REFERRALS
			</Heading>
			<VStack gap={5} alignSelf={'stretch'} alignItems={'flex-start'} py={'2rem'}>
				<Text textAlign="left" fontSize="16px" ml={'2rem'}>
					You sent a personal referral link to the below emails.
				</Text>
				<TableContainer width="100%">
					<Table>
						<Thead>
							<Tr>
								<Th>DATE</Th>
								<Th>EMAIL ADDRESS</Th>
								<Th>REGISTERED</Th>
								<Th>POINTS EARNED</Th>
							</Tr>
						</Thead>
						<Tbody>
							{/* TODO Extract Stripe color to configuration */}
							{sampleData.map((row, idx) => (
								<Tr key={idx}>
									<Td
										backgroundColor={isEven(idx) ? '#fcf3f2' : 'inherit'}
										fontSize={{ base: 'sm' }}
									>
										{row.date}
									</Td>
									<Td
										backgroundColor={isEven(idx) ? '#fcf3f2' : 'inherit'}
										fontSize={{ base: 'sm' }}
									>
										{row.email}
									</Td>
									<Td
										backgroundColor={isEven(idx) ? '#fcf3f2' : 'inherit'}
										fontSize={{ base: 'sm' }}
									>
										{row.registered ? 'Yes' : 'No'}
									</Td>
									<Td
										backgroundColor={isEven(idx) ? '#fcf3f2' : 'inherit'}
										fontSize={{ base: 'sm' }}
									>
										{row.earned ? `${row.earned.toString()} PTS` : ''}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<HStack justifyContent="flex-start" ml={'2rem'}>
					<Button
						colorScheme="primary"
						size="md"
						textTransform="uppercase"
						onClick={() => {
							setShowShareLinks(!showShareLinks);
						}}
					>
						SHARE MORE PERKS
					</Button>
					{showShareLinks && (
						<VStack>
							<List spacing={3}>
								<ListItem>
									<ListIcon>
										<BiMessageAltDetail />
									</ListIcon>
									<Link href="https://ebbo.com" isExternal>
										Ebbo.com
									</Link>
								</ListItem>
							</List>
						</VStack>
					)}
				</HStack>
			</VStack>
		</VStack>
	);
};

export default AccountHistoryReferral;
