import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import PerksThreeColumn from '../components/contentBlocks/PerksThreeColumn';
import HeroImageRight from '../components/hero/HeroImageRight';
import PageHeaderCentered from '../components/header/PageHeaderCentered';
import HeroImageLeft from '../components/hero/HeroImageLeft';
import { ScrollRestoration } from 'react-router-dom';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';
import { AppContext } from '../../data/globalstate';
import {
	EarningGallery,
	EarningGrid,
	PollModal,
	ProfileModal,
	ReviewModal,
	VideoPlayer,
} from '../components/earningActions';
import { IEarningAction } from '../../domain/models';

const EarnPointsPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user } = useContext(AppContext);
	const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
	const [showPoll, setShowPoll] = useState<boolean>(false);
	const [showProfileForm, setShowProfileForm] = useState<boolean>(false);
	const [showReivewForm, setShowReviewForm] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<IEarningAction>();
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Earn Points" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<HeroImageRight
				marginTop="40px"
				heroImageUrl="/media/images/MC_Image_620x425.jpg"
				heroImageAlt="Mountain Clothing"
				contentBgColor="secondary.500"
			/>
			{!user && (
				<>
					<HeroImageLeft
						marginTop="40px"
						heroImageUrl="https://burst-staging.ebbo.com/media/wysiwyg/home/GrandCanyon_Img.png"
						heroImageAlt="The North Face"
						contentBgColor="#20513f"
						showSpecialBadge
					/>
					<EarningGallery
						setSelectedAction={setSelectedAction}
						setShowVideoPlayer={setShowVideoPlayer}
						setShowPoll={setShowPoll}
						setShowProfileForm={setShowProfileForm}
						setShowReviewForm={setShowReviewForm}
					/>
					<PageHeaderCentered />
					<PerksThreeColumn />
				</>
			)}
			{user && (
				<EarningGrid
					setSelectedAction={setSelectedAction}
					setShowVideoPlayer={setShowVideoPlayer}
					setShowPoll={setShowPoll}
					setShowProfileForm={setShowProfileForm}
					setShowReviewForm={setShowReviewForm}
				/>
			)}
			<FooterThreeColumn />
			<VideoPlayer
				showModal={showVideoPlayer}
				setShowModal={setShowVideoPlayer}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			<PollModal
				showModal={showPoll}
				setShowModal={setShowPoll}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			<ProfileModal
				showModal={showProfileForm}
				setShowModal={setShowProfileForm}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
			{/* TODO Reviews PULSE-75 */}
			<ReviewModal
				showModal={showReivewForm}
				setShowModal={setShowReviewForm}
				earningAction={selectedAction}
				setSelectedAction={setSelectedAction}
			/>
		</Box>
	);
});

export const AnimatedEarnPointsPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <EarnPointsPage ref={ref} />),
);

export default EarnPointsPage;
